import { Button, Modal ,Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { approveDealAction, declineDealAction } from '../../../../redux/actions/dealsAction'
import { toast } from 'react-toastify'
import { userIdAtom } from '../../../../atom/authuserData/userdata'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'

const ApprovalConfirmModal = ({ deal, selectedButtonType, showConfirmationModal, onCancel }) => {
  const [userId, setuserId] = useRecoilState(userIdAtom);

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading: approveDealLoading } = useSelector((state) => state.approveDeal);
  const { loading: declineDealLoading } = useSelector((state) => state.declineDeal);

  const [declineReason, setDeclineReason] = useState('');

  // Optionally, you can set the default reason when the modal opens (if needed)
  useEffect(() => {
    if (showConfirmationModal) {
      // Reset the decline reason if the modal is opened
      setDeclineReason('');
    }
  }, [showConfirmationModal]);

  const handleApproveDeal = async () => {
    const result = await dispatch(approveDealAction(userId, deal?._id));
    if (result?.status === 1) {
      toast.success(result?.message);
      navigate('/submission-board')
    } else {
      toast.error(result?.message);
    }
  }

  const handleDeclineDeal = async (reason) => {
    const result = await dispatch(declineDealAction(userId, deal?._id,reason));
    console.log("Decline reason ",result)
    if (result?.status === 1) {
      toast.success(result?.message);
      navigate('/submission-board')
    } else {
      toast.error(result?.message);
    }
  }

  const handleConfirm = async () => {
    // const reasonInput = document.getElementById('decline-reason');
    // const reason = reasonInput.value;
    if (selectedButtonType === 'Approve') {
      handleApproveDeal()
    } else {
      handleDeclineDeal(declineReason)
    }
  }

  return (
    <Modal
      centered
      footer={null}
      open={showConfirmationModal}
      onCancel={onCancel}
      onOk={onCancel}
    >
      <div className=''>
        <p className='text-base'>Are you sure you want to {" "}
          <span className='capitalize font-semibold'>{selectedButtonType === 'Approve' ? "Claim the" : "Decline"} deal?</span>
        </p>
        {selectedButtonType === 'Decline' && (
          <Input.TextArea
            id="decline-reason"
            placeholder="Enter decline reason"
            value={declineReason} // Bind the value of the input to state
            onChange={(e) => setDeclineReason(e.target.value)}
            rows={4}
            style={{ marginTop: 10 }}
          />
        )}
      </div>
      <div className='mt-4 flex justify-end items-center gap-3'>
        <Button
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button
          loading={approveDealLoading || declineDealLoading}
          onClick={() => handleConfirm()}
          className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default ApprovalConfirmModal