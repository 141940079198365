import React, { useEffect, useState } from "react";
import ReportTable from "../../../common/components/AdminReportTable";
import "../../AdminStyle/report.css";
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import ReusableTable from "../../../common/tables/Table";
import moment from "moment/moment";
import { useRecoilState } from "recoil";
import { userIdAtom } from "../../../atom/authuserData/userdata";
import { useDispatch, useSelector } from "react-redux";
import { getAllReportAction } from "../../../redux/actions/reportAction";
import { Spin } from "antd";
import searchIcon from '../../../data/Group 536.png';

export default function Reports(){

    const [userId] = useRecoilState(userIdAtom);
    const [searchText,setSearchText]= useState('')

    const dispatch = useDispatch();

    const {loading:allReportsLoading, allReports: reports , data:report} = useSelector(state=>state.getAllReport);


    console.log("Report Data ",report )

    console.log("Report ::" , reports)

    useEffect(()=>{
        dispatch(getAllReportAction(userId));
    },[dispatch])

    const reporttableData = (reports && Array.isArray(reports))
    ? reports.filter(report =>
      report.companyName.toLowerCase().includes(searchText.toLowerCase())
    ).map(reportData => ({
        companyName: reportData?.companyName,
        paybackAmount: reportData?.paybackAmount,
        date: reportData?.date,
        companyNameFunded:reportData?.lender,
        lender: reportData?.lender,
        fundedAmount:reportData?.fundedAmount,
        upsell: reportData?.upsellPercentage,
        split: reportData?.split,
        teamCM: reportData?.teamCommision,
        leader: reportData?.leader,
    })) : [];

  // Ensure safe access when sorting
  const sortedreportsData = reporttableData?.sort((a, b) => moment(b.date) - moment(a.date));
  useEffect(() => {
    console.log("Sorted Report Data::", sortedreportsData)
  })
    
  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US', 
        { style: 'currency', currency: 'USD', 
            minimumFractionDigits: 0, 
            maximumFractionDigits: 2
         }).format(amount)
  };

    const columns = React.useMemo(
        () => [
          // {
          //   Header: 'Review',
          //   accessor: 'review',
          // },
          {
              Header: 'Company Name',
              accessor: 'companyName',
              Cell: ({ value }) => (
                  <span className="w-[120px] block">{value}</span> // Adjust width as needed
                ),
            },
            {
                Header: 'Payback Amount',
                accessor: 'paybackAmount',
                Cell: ({ value }) => (
                    <span className="w-[120px] block">
                    {isNaN(value) ? '-' : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(value)}
                </span>
            ),
        },
        {
          Header: 'Date',
          accessor: 'date',
          Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span>
        },
          {
            Header: 'Company Name (funded)',
            accessor: 'companyNameFunded',
            Cell: ({ value }) => (
              <span className="w-[120px] block">{value}</span> // Adjust width as needed
            ),
          },
          {
            Header: 'Funded Amount',
            accessor: 'fundedAmount',
            Cell: ({ value }) => (
                <span className="w-[120px] block">
                    {isNaN(value) ? '-' : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(value)}
                </span>)
          },
          {
            Header: 'Upsell %',
            accessor: 'upsell',
            Cell: ({ value }) => (
                <span className="w-[60px] block">{value}%
          </span>
        ),
          },
          {
            Header: 'Split',
            accessor: 'split',
            Cell: ({ value }) => (
                <span className="w-[120px] block">
                    {isNaN(value) ? '-' : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(value)}
                </span>
        ),
          },
          {
            Header: 'Team CM',
            accessor: 'teamCM',
            Cell: ({ value }) => (
                <span className="w-[120px] block">
                    {isNaN(value) ? '-' : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(value)}
                </span>
        ),
          },
          {
            Header: 'Leader',
            accessor: 'leader',
            Cell: ({ value }) => (
                <span className="w-[120px] block">{value}
          </span>)
          },    
        ],
        []
      );
    
    return(
        <div className="flex h-screen " >
            <DashboardTab />
            <div className="reportContainer flex-1 flex flex-col">
                <GoodMorningBar />
            <main className="flex-1 bg-background-color p-6 h-full flex flex-col overflow-x-auto">
                
                {allReportsLoading ? (
                    <div className='w-full  pt-16 flex justify-center items-center'>
                        <Spin />
                    </div>
                ) : (
                    <div className="container gap-0">
                        <div className="container-menu">
                            <div className="sendMail">Send mail</div>
                            <div className="paid">Paid by lender</div>
                            <div className="p-3">Totals</div>
                            <div className="p-3">{formatAmount(report)}</div>
                        </div>

                        <div className='mt-4 text-black'>
              <div className='bg-white rounded-tl-xl rounded-tr-xl p-3'>
                <div className="flex items-center bg-background-color rounded-3xl p-2 h-10 w-full mr-3">
                  <img
                    src={searchIcon}
                    alt="Search Icon"
                    className="w-8 h-8 mr-2"
                  />
                  <input
                    type="text"
                    placeholder="Search information"
                    className="bg-background-color focus:outline-none pl-3 w-full"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
              <div className="table w-full table-fixed">
                <ReusableTable columns={columns} data={sortedreportsData} />
              </div>
            </div>
                    </div>
                )}
            </main>
            </div>
        </div>
    );
}