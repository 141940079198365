import React from 'react';
import { Modal } from 'antd';

const ShowCompanyModal = ({ visible, onClose, deal }) => {
  return (
    <Modal
      title="Company Details"
      visible={visible}
      onCancel={onClose}
      footer={null} 
      width={600}
    >
      {deal ? (
        <div>
            <p><strong>City:</strong> {deal.city}</p> 
            <p><strong>Company Name:</strong> {deal.company_name}</p> 
            <p><strong>Created At: </strong> {deal.createdAt}</p>
            <p><strong>Email: </strong> {deal.email}</p>
            <p><strong>First Name: </strong> {deal.firstName}</p>
            <p><strong>Last Name: </strong> {deal.lastName}</p>
            <p><strong>Phone: </strong> {deal.phone}</p>
            <p><strong>Status: </strong> {deal.status}</p>
            <p><strong>Queue: </strong> {deal.queue}</p>
            <p><strong>user_id: </strong> {deal.user_id}</p>
            <p><strong>Lead Source: </strong> {deal.lead_source}</p>
        </div>
      ) : (
        <p>No details available</p>
      )}
    </Modal>
  );
};

export default ShowCompanyModal;
