// GoodMorningBar.js
import React, { useEffect, useState } from 'react';
// import searchIcon from '../../data/Group 536.png';
import * as XLSX from 'xlsx';
import notification from '../../data/notification.png';
import NotificationModal from '../components/notification'; // import the modal component
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Upload, Dropdown, Modal, Input } from 'antd';
import { PiCloudArrowUp } from 'react-icons/pi';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userIdAtom, userName } from '../../atom/authuserData/userdata';
import { getAllLeadsAction, postCsvFileAction } from '../../redux/actions/leadsActions';
import { toast } from 'react-toastify';


const GoodMorningBar = ({ userNames }) => {
  const { role } = useSelector((state) => state.auth);
  const [userId, setuserId] = useRecoilState(userIdAtom);
  const [isModalOpen, setModalOpen] = useState(false);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [showAddLead, setShowAddLead] = useState(false);
  const [file, setFile] = useState(null)
  const [viewStatistics, setViewStatistics] = useState(false)

  const { Dragger } = Upload;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const routename = location.pathname;
  const selectedTab = useSelector((state) => state.auth);
  const user_name = useRecoilValue(userName);

  const { loading: uploadLoading } = useSelector(state => state.postCsvFile);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleCSVModal = () => {
    setShowCSVModal(true);
  }

  const handleAddLead = () => {
    setShowAddLead(true);
  }

  const handleCancel = () => {
    setShowCSVModal(false);
    setShowAddLead(false);
  }

  const uploadProps = {
    onRemove: () => {
      setFile(null)
    },
    beforeUpload: (inputFile) => {
      if (viewStatistics) setViewStatistics(false)
      const isCSVFormat = /\.xlsx$/.test(inputFile.name)
      // && /\.sheet$/.test(inputFile.type)
      if (isCSVFormat) setFile(inputFile)
      else void console.log('Please upload in .xlsx file format')
      return false
    },
    maxCount: 1,
    fileList: file === null ? [] : [file],
    accept: ".xlsx"
  }

  const convertExcelToJson = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        let jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        jsonData = jsonData.map(row => {
          const cleanedRow = {};
          for (let key in row) {
            const cleanKey = key.trim();
            cleanedRow[cleanKey] = row[key];
          }
          return cleanedRow;
        });

        resolve(jsonData);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleUpload = async (e) => {
    // e.stopPropagation()
    if (file) {
      const jsonData = await convertExcelToJson(file);
      const finalizedData = jsonData.map((data) => {
        return {
          "cName": data.CompanyName,
          "firstName": data.FirstName,
          "lastName": data.LastName,
          "email": data.Email,
          "phone": data.PhoneNumber,
          "city": data.City,
          "street": data.Street,
          "leadSource": data.LeadSource,
          "tag": data.LeadTag
        }
      })

      const result = await dispatch(postCsvFileAction(userId, { leads: finalizedData }));
      if (result?.status === 1) {
        setShowCSVModal(false);
        setFile(null);
        toast.success(result?.message)
        dispatch(getAllLeadsAction(userId))
      } else {
        toast.error(result?.message)
      }
    }
  }

  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex items-center space-x-4">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYPgwYYhHGntkkaWUE7g3jGjMkqSkSYbGq4g&s"
          alt="User Avatar"
          className="w-12 h-12 rounded-full border-2 border-gray-300"
        />
        <div className="text-good-morning-text font-semibold">
          👋 Good morning {user_name}
        </div>
      </div>
      <div className='flex w-auto relative items-center'>
        {/* <div className="flex items-center bg-white rounded-3xl p-2 h-10 w-full mr-3">
          <img
            src={searchIcon}
            alt="Search Icon"
            className="w-8 h-8 mr-2"
          />
          <input
            type="text"
            placeholder="Search information"
            className="bg-white focus:outline-none pl-3 w-full"
          />
        </div> */}
        <img
          src={notification}
          alt="Notification Icon"
          className="w-8 h-8 cursor-pointer"
          onClick={toggleModal}
        />
        <NotificationModal isOpen={isModalOpen} onClose={toggleModal} />
        {/* {
          routename == 'Deals' && role == 'admin' ?
        <div className="mr-8 ml-4 h-9 w-80 py-1 text-center rounded-lg text-white bg-custom-green font-semibold cursor-pointer">
          Add new lender
        </div>
        : null
        } */}
        {
          routename === '/leads' ?
            <div className='ml-4 flex justify-center items-center gap-5'>
              <Dropdown
                menu={{
                  items: []
                }}
                trigger={['click']}
                className='flex items-center justify-center bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
                dropdownRender={_ => (
                  <div className='flex flex-col justify-center items-start gap-2 bg-white rounded-md p-2'>
                    <span
                      className='flex justify-start items-center gap-1 cursor-pointer font-medium'
                      onClick={() => handleCSVModal()}
                    >
                      <PiCloudArrowUp />
                      Upload CSV or excal
                    </span>
                    <span
                      className='flex justify-start items-center gap-1 cursor-pointer font-medium'
                      onClick={() => navigate("/add-lead")}
                    >
                      <PlusOutlined />
                      Add Lead
                    </span>
                    <span
                      className='flex justify-start items-center gap-1 cursor-pointer font-medium'
                      onClick={() => navigate("/leads ")}
                    >
                      <PlusOutlined />
                      Add existing Lead
                    </span>
                  </div>
                )}
              >
                <Button>
                  Add new lead
                  <PlusOutlined />
                </Button>
              </Dropdown>
            </div>
            : null
        }
        <Modal
          open={showCSVModal}
          width={700}
          footer={null}
          centered
          onOk={handleCancel}
          onCancel={handleCancel}
          title={<h6 className="text-lg">Upload CSV or excel</h6>}
        >
          <div className="flex flex-col items-center justify-center gap-2">
            <Card className="mt-2 mb-8 w-full" bodyStyle={{ padding: 0 }}>
              <div className="h-[296px] w-full">
                {/* @ts-expect-error */}
                <Dragger {...uploadProps}>
                  <span className="text-primary text-6xl flex justify-center">
                    <PiCloudArrowUp />
                  </span>
                  <p className="ant-upload-text font-semibold py-3 w-full">
                    Select a file or drag and drop here
                  </p>
                  <p className="text-sm w-full">
                    EXL or CSV file size no more than 100MB
                  </p>
                  <Button
                    disabled={file}
                    className='mt-8 bg-custom-green text-white'
                  >
                    Select File
                  </Button>
                </Dragger>
              </div>
            </Card>
            <div className='flex justify-end items-center w-full'>
              {/* <div>
                {file &&
                  <Input
                    placeholder='Rename the file'
                    className='w-60'
                    suffix=".xlsx"
                    onChange={e => setRenamedFileName(e.target.value)}
                  />
                }
              </div> */}
              <Button
                onClick={() => handleUpload()}
                loading={uploadLoading}
                className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
              >
                Upload
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default GoodMorningBar;
