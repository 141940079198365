import React, { useEffect, useState } from "react";
import "../../AdminStyle/adminTable.css";
import '../../AdminStyle/SubmissionChartBar.css';
import { SemicircularProgressBar } from "../../../common/components/SemiCircularProgressBar";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import { getSubmissionDataAction } from '../../../redux/actions/submissionChartAction';
import { useDispatch, useSelector } from 'react-redux';
import { userIdAtom } from '../../../atom/authuserData/userdata';
import { useRecoilState } from 'recoil';
import { Avatar, Button, List, Spin } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import moment from 'moment';

export default function SubmissionChart() {
  const [submissionListData, setSubmissionListData] = useState([]);
  const [todayTotalCount, setTodayTotalCount] = useState(0);
  const [fromDate, setFromDate] = useState(moment(new Date()).subtract(1, "month").startOf("day").format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [userId, setuserId] = useRecoilState(userIdAtom);
  const dispatch = useDispatch();

  const { loading: submissionDataLoading, submissionData , totalSubmission , approvedCounts } = useSelector(state => state.getSubmissionData);

  
  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };
  const handlegetSubmissionData = async () => {
    await dispatch(getSubmissionDataAction(userId, fromDate, toDate));
  }

  const handleSubmit = async () => {
    if (fromDate && toDate) {
      handlegetSubmissionData();
    }
  }

  useEffect(() => {
    console.log("approvedCounts::",totalSubmission)
    console.log("Comission::",totalSubmission?.approvedCounts)
    handlegetSubmissionData();
  }, []);

  useEffect(() => {
    console.log('Submission Data:', submissionData?.data);
    if (submissionData && Object.keys(submissionData).length > 0) {
      let formattedData = [] || "";
      let sum = 0;
  
      for (let key in submissionData) {
        if (submissionData.hasOwnProperty(key)) {
          const fundedAmount = submissionData[key];
          // Check if fundedAmount is an object, then extract required data
          if (typeof fundedAmount === "object") {
            sum += fundedAmount.thisMonth ?? 0; // Adjust based on your needs
            formattedData.push({
              agent: key.trim(),
              totalFunded: formatAmount(fundedAmount.thisMonth ?? 0) // Or any other relevant field
            });
          } else {
            sum += fundedAmount;
            formattedData.push({
              agent: key.trim(),
              totalFunded: formatAmount(fundedAmount)
            });
          }
        }
      }
  
      setSubmissionListData(formattedData);
      setTodayTotalCount(sum);
    } else {
      console.warn("No submission data received for the given date range.");
    }
  }, [submissionData]);
  

  


  const tableData = [
    { agent: "Ted Mosby", totalFunded: 6 },
    { agent: "Michael Scott", totalFunded: 10 },
    { agent: "Pam Beesly", totalFunded: 3 },
    { agent: "Jim Hatpert", totalFunded: 8 },
    { agent: "Dwight Shrute", totalFunded: 6 },
    { agent: "Dario Benedetto", totalFunded: 6 },
    { agent: "Nick Fury", totalFunded: 7 },
    { agent: "Tony Stark", totalFunded: 5 },
    { agent: "Natasha Romanoff", totalFunded: 2 },
    { agent: "Steve Rogers", totalFunded: 6 }
  ];

  const progressBar1 = [
    { value: 10, count: 1 },
    { value: 80, count: 8 },
    { value: 20, count: 2 },
    { value: 50, count: 5 }
  ];
  const progressBar2 = [
    { value: 50, count: 5 },
    { value: 30, count: 3 },
    { value: 30, count: 3 },
    { value: 90, count: 9 }
  ];
  const progressBar3 = [
    { value: 20, count: 2 },
    { value: 40, count: 4 },
    { value: 60, count: 6 },
    { value: 80, count: 8 }
  ];
  const progressBar4 = [
    { value: 60, count: 6 },
    { value: 20, count: 2 },
    { value: 40, count: 4 },
    { value: 100, count: 10 }
  ];

  const onChangeFrom = (date) => {
    console.log(date, "from");
    setFromDate(date);
  }
  const onChangeTo = (date) => {
    console.log(date, "to");
    setToDate(date);
  }

  const Avatar = ({ agent }) => {
    const trimedSplitedNames = agent?.trim()?.split(" ");
    if (trimedSplitedNames?.length > 1) {
      return (
        <div className="w-10 h-10 flex items-center justify-center gap-5 bg-[#8AD7B7] rounded-full ml-4">
          <p className='text-lg font-semibold text-[#004629]'>{trimedSplitedNames[0].charAt(0) + trimedSplitedNames[1].charAt(0)}</p>
        </div>
      );
    } else if (trimedSplitedNames?.length === 1) {
      return (
        <div className="w-10 h-10 flex items-center justify-center gap-5 bg-[#8AD7B7] rounded-full ml-4">
          <p className='text-lg font-semibold text-[#004629]'>{trimedSplitedNames[0].charAt(0)}</p>
        </div>
      );
    }
  }

  const calculateProgressBarData = (approvedCounts) => {
    return approvedCounts.map((entry) => {
      const { Approved, totalSubmissions , agentName } = entry;
      const value = totalSubmissions > 0 ? (Approved / totalSubmissions) * 100 : 0;
      console.log("Agent Name::",agentName)
      return {
        value: value,
        count: Approved,
        agentName:agentName
      };
    });
  };

  useEffect(() => {
    if (totalSubmission && totalSubmission?.approvedCounts) {
      const progressBarData = calculateProgressBarData(totalSubmission?.approvedCounts);
      // Now, we can update the progress bar data state (or use it directly in JSX)
      setProgressBarData(progressBarData);
    }
  }, [totalSubmission]);

  const [progressBarData, setProgressBarData] = useState([]);

  return (
    <div className="flex h-auto">
      <DashboardTab />
      <main className="flex-1 bg-background-color p-6 h-screen flex flex-col">
        <GoodMorningBar userName={'Manish'} />
        <div className="flex w-full mt-6">
          <div className="container w-[50%]">
            <div className='w-full bg-white'>
              <div className='flex justify-between bg-[#e2e2e2] text-black h-12 items-center px-4 rounded-tl-lg rounded-tr-lg'>
                <h2>Agent</h2>
                <h2>Total funded</h2>
              </div>
              <Spin spinning={submissionDataLoading}>
                <List
                  dataSource={submissionListData}
                  renderItem={(item, index) => (
                    <List.Item key={index}>
                      <List.Item.Meta
                        avatar={<Avatar agent={item?.agent} />}
                        title={<h1 className='text-base'>{item?.agent}</h1>}
                      />
                      <p className='pr-10'>{item?.totalFunded}</p>
                    </List.Item>
                  )}
                />
              </Spin>
            </div>
          </div>

          <div className="second-container w-[50%] pt-4">
            <div className="total-count-container">
              <div
                className="bg-white w-32 rounded-lg flex flex-col justify-center items-center p-3"
              >
                <span className='text-[35px] text-[#00A762] font-bold'>{new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(totalSubmission?.totalSubmissions || 0)}</span>
                <span className='text-base font-semibold'>
                  Total Submissions
                  </span>
              </div>
              <div className="sendMail flex gap-8 items-center">
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-5">
                    Date from
                    <input
                      value={fromDate}
                      type="date"
                      onChange={(e) => onChangeFrom(e.target.value)}
                      max={toDate || moment(new Date()).format("YYYY-MM-DD")}
                      className='cursor-pointer'
                    />
                  </div>
                  <div className="flex items-center gap-10">
                    Date to
                    <input
                      value={toDate}
                      type="date"
                      onChange={(e) => onChangeTo(e.target.value)}
                      min={fromDate}
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      className='cursor-pointer'
                    />
                  </div>
                </div>
                <Button
                  size='medium'
                  className='text-white bg-[#00A762] px-[1rem] py-[5px] rounded-lg'
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </div>
            </div>
            <div className="countOfAgent flex flex-col bg-white rounded-xl">
              <div className="title">Count of Agent</div>
              <div className="flex justify-between gap-6 p-4">
              {progressBarData.map((data, index) => {
                return (
                  <div key={index} className="flex flex-col items-center gap-4">
                    <SemicircularProgressBar
                      value={data.value}
                      count={data.count}
                    />
                    <div className="agent">{data?.agentName}</div>
                  </div>
                );
              })}
              </div>
              {/* <div className="flex justify-between gap-6 p-4">
                {progressBar2.map((data, index) => {
                  return (
                    <div key={index} className="flex flex-col items-center gap-4">
                      <SemicircularProgressBar
                        value={data.value}
                        count={data.count}
                      />
                      <div className="agent">Ted Mosby</div>
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-between gap-6 p-4" style={{ backgroundColor: "#F0F5F3" }}>
                {progressBar3.map((data, index) => {
                  return (
                    <div key={index} className="flex flex-col items-center gap-4">
                      <SemicircularProgressBar
                        value={data.value}
                        count={data.count}
                      />
                      <div className="agent">Ted Mosby</div>
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-between gap-6 p-4">
                {progressBar4.map((data, index) => {
                  return (
                    <div key={index} className="flex flex-col items-center gap-4">
                      <SemicircularProgressBar
                        value={data.value}
                        count={data.count}
                      />
                      <div className="agent">Ted Mosby</div>
                    </div>
                  );
                })}
              </div> */}
            </div>
          </div>

        </div>
      </main>

    </div>
  );
}