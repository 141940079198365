import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from 'react-router';
import DocumentList from "./DocumentList";
import { useRecoilState } from 'recoil';
import ReusableTable from '../../../../../common/tables/Table';
import { useDispatch, useSelector } from 'react-redux';
import { userIdAtom } from '../../../../../atom/authuserData/userdata';
import { Button, Spin } from 'antd';
import moment from 'moment';
import { TbPointFilled } from 'react-icons/tb';
import { FiEdit2, FiUploadCloud } from "react-icons/fi";
import AddNewLenderToDeal from '../../../../AdminScreens/AddNewLender/AddNewLenderToDeal';
import { getDealsByLenderAction } from '../../../../../redux/actions/lenderAction';
import UploadFileModal from './UploadFileModal';
import { getAllFilesAction } from '../../../../../redux/actions/leadsActions';
import { sendDealToSubmissionBoardAction } from '../../../../../redux/actions/dealsAction';
import { toast } from 'react-toastify';

export default function AgentDealLender() {
    const [dealsLenderTableData, setDealsLenderTableData] = useState([]);
    const [showAddNewLenderToDealModal, setShowAddNewLenderToDealModal] = useState(false);
    const [showFileuploadModal, setShowFileuploadModal] = useState(false);
    const [allFilesData, setAllFilesData] = useState([])
    const [template,setTemplat] =useState("hello , please see attached deal.")
    const [isEditing,setIsEditing] =useState(false)

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAllFiles = async () => {
        const result = await dispatch(getAllFilesAction(userId, deal?._id));
        if (result?.status === 1) {
            setAllFilesData(result?.files)
        } else {
            setAllFilesData([])
        }
    }

    const { role } = useSelector((state) => state.auth);
    const [userId, setuserId] = useRecoilState(userIdAtom);
    const { selectedTab: tabSelected } = useSelector((state) => state.auth);
    const { loading: allDealsLenderLoading, dealsLender } = useSelector(state => state.getDealsByLender);
    const { loading: sendDealToSBLoading } = useSelector(state => state.sendDealToSubmissionBoard);
    const { deal } = location.state || {};

    useEffect(() => {
        dispatch(getDealsByLenderAction(userId, deal?._id));
        getAllFiles()
    }, []);

    useEffect(() => {
        let filteredData = [];
        if (dealsLender?.length > 0) {
            dealsLender?.forEach((lender) => {
                filteredData.push({
                    lender: {
                        company: lender?.lender_name,
                        ceo: `${lender?.owner_firstName}`,
                        ceoFirstName: lender?.owner_firstName,
                        ceoMail: lender?.owner_mail
                    },
                    position: lender?.posOfWiilng,
                    credit: lender?.credit ?? "N/A",
                    amount: lender?.payment,
                    monthlyMin: lender?.monthly_minimums,
                    status: lender?.status,
                    createdAt: lender?.createdAt,
                });
            });
        }
        setDealsLenderTableData(filteredData);

    }, [dealsLender]);

    const getStatusClassName = (status) => {
        switch (status) {
            case "Pending":
                return "status-pending";
            case "Declined":
                return "status-declined";
            case "Approved":
                return "status-approved";
            default:
                return "";
        }
    };

    const dealsLenderColumns = useMemo(() => {
        const columns = [
            {
                Header: 'Lender',
                accessor: 'lender',
                Cell: ({ value }) => (
                    <div>
                        <div className="text-good-morning-text font-semibold capitalize">{value.company} / {value.ceoFirstName}</div>
                        {/* <div>{value.ceoMail}</div> */}
                    </div>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ value }) => (
                    <div className={`flex items-center space-x-2 px-2 py-1  ${value === 'Declined' ? 'bg-declined-bgcolor' : value === "Approved" ? "bg-operation-green" : "bg-pending-background"} text-white rounded-2xl border w-28`}>
                        <span className={`h-2 w-2 ${value === 'Declined' ? 'bg-red-600' : value === "Approved" ? 'bg-green-500' : 'bg-pendingText'} rounded-full`}></span>
                        <span className={` ${value === 'Declined' ? 'text-red-600' : value === "Approved" ? "text-approved-text" : "text-pendingText"} font-semibold`}>{value}</span>
                    </div>
                ),
            },
            {
                Header: 'Position',
                accessor: 'position',
                Cell: ({ value }) => (
                    <span>{value}</span>
                ),
            },
            {
                Header: 'Credit',
                accessor: 'credit',
                Cell: ({ value }) => (
                    <span>{value}</span>
                ),
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                Cell: ({ value }) => (
                    <span>{value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value) : "-"}
                    </span>
                  ),
            },
            {
                Header: 'Monthly Min',
                accessor: 'monthlyMin',
                Cell: ({ value }) => (
                    <span>{value ? `$${value}` : "-"}</span>
                ),
            },
            // {
            //     Header: 'Status',
            //     accessor: 'status',
            //     Cell: ({ value }) => (
            //         <div className={getStatusClassName(value)}>
            //             <TbPointFilled className="mr-2" /><span>{value}</span>
            //         </div>
            //     ),
            // },
            // {
            //     Header: 'Comment',
            //     accessor: 'comment',
            //     Cell: ({ value }) => (
            //         <span>{value ? moment(value).format("MM/DD/YYYY, HH:MM:SS A") : "-"}</span>
            //     ),
            // },
        ];

        // if (role !== "agents" && tabSelected === 'Submission Board') {
        //     columns.push({
        //         Header: 'Add lender to deal',
        //         accessor: 'edit',
        //         Cell: () => (
        //             <div className="flex">
        //                 <FiEdit2 className="text-gray-600 cursor-pointer" size={20} onClick={() => setShowEditLenderModal(true)} />
        //             </div>
        //         ),
        //     });
        // }

        return columns;
    }, [role]);

    const handleAddNewLender = () => {
        setShowAddNewLenderToDealModal(true);
    }

    const handleTemplateChange=(e)=>{
        setTemplat(e.target.value)
    }

    const handleEditClick=()=>{
        setIsEditing(!isEditing)
    }

    const sendDealToLender = async () => {
        const result = await dispatch(sendDealToSubmissionBoardAction(userId, deal?._id,template));
        if (result?.status === 1) {
            toast.success(result?.data?.message)
            navigate('/submission-board')
        } else {
            toast.error(result?.data?.message)
        }
    }

    return (
        <>
            <div className="flex flex-col gap-4 w-full">
                <div className='flex gap-4 min-h-[485px]'>
                    <div className="flex flex-col items-center w-[40%] rounded-lg px-[0.8rem] py-[1rem] bg-white">
                        <div className="flex text-[1.2rem] text-[#0A2759] w-[100%] justify-between font-[500] px-[0.5rem] py-[0.5rem]">
                            Document
                            <Button
                                className="flex gap-2 border border-[#00A762] rounded-lg px-2 py-1.5 items-center text-[1rem]"
                                onClick={() => setShowFileuploadModal(true)}
                            >
                                <FiUploadCloud /> Upload
                            </Button>
                        </div>
                        <DocumentList allFilesData={allFilesData} setAllFilesData={setAllFilesData} />
                    </div>
                    <div className='w-[60%]'>
                        <div className="bg-white flex justify-between w-full py-5 rounded-tl-md rounded-tr-md">
                            <div className="flex items-center">
                                <div className="ml-8 text-good-morning-text font-bold text-xl">
                                    Lenders
                                </div>
                            </div>
                            {role === 'agents' && tabSelected === 'Submission Board' ?
                                null
                                : <div
                                    className="h-9 px-14 py-1 rounded-lg text-white bg-custom-green font-semibold cursor-pointer"
                                    onClick={() => handleAddNewLender()}
                                >
                                    Add new lender
                                </div>}
                        </div>
                        <div className="h-auto w-full overflow-x-auto">
                            <Spin spinning={allDealsLenderLoading}>
                                <ReusableTable columns={dealsLenderColumns} data={dealsLenderTableData} maxRow={5} />
                            </Spin>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2 text-[#0A2759] font-[500] w-[100%]">
                    <div className="flex flex-col gap-2 bg-white px-[2rem] py-[1rem] rounded-lg">
                        <div className="flex justify-between gap-2 items-center mt-2 text-[1.2rem]">
                            Template
                            <button className="text-white bg-[#00A762] px-4 py-1 rounded text-[1rem]" onClick={handleEditClick}>{isEditing?"Save":"Edit"}</button>
                        </div>
                        <textarea className="flex gap-2 px-3 py-1 h-[100px] rounded-lg border border-[#D0D3D9] outline-none"
                            value={template}
                            onChange={handleTemplateChange}
                            disabled={!isEditing}
                        />
                    </div>
                    <div className="flex gap-8 self-end mt-2">
                        <Button className="text-[#C80000] font-[500]">Cancel</Button>
                        <Button
                            loading={sendDealToSBLoading}
                            className="text-white bg-[#00A762] px-[1rem] py-[5px] rounded-lg"
                            onClick={() => sendDealToLender()}
                        >
                            Send deal to lender
                        </Button>
                    </div>
                </div>
                {showAddNewLenderToDealModal &&
                    <AddNewLenderToDeal
                        deal={deal}
                        showAddNewLenderToDealModal={showAddNewLenderToDealModal}
                        setShowAddNewLenderToDealModal={setShowAddNewLenderToDealModal}
                    />
                }
                {showFileuploadModal &&
                    <UploadFileModal
                        getAllFiles={() => getAllFiles()}
                        deal={deal}
                        visible={showFileuploadModal}
                        onCancel={() => setShowFileuploadModal(false)}
                    />
                }
            </div>
        </>
    )
}