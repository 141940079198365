import React, { useEffect, useState } from 'react';
import { FaRegFolder ,FaDownload } from 'react-icons/fa';
import { MdUploadFile } from "react-icons/md";
import { Input, Spin, Upload ,Button } from 'antd';
import PDF from "react-pdf-js";
import { useDispatch, useSelector } from 'react-redux';
import { getAllFilesAction, resetAllFilesAction, uploadFileAction } from '../../../../redux/actions/leadsActions';
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../../atom/authuserData/userdata';
import { toast } from 'react-toastify';
import { FcOk } from "react-icons/fc";
import animatedOk from "../../../../data/ok.gif";
import PdfPreviewModal from '../../DealsScreen/InduvidualDealTabs/AgentDealLender/PdfPreviewModal';

// pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf.worker.mjs";

const { Dragger } = Upload;

const FilesTab = ({ deal }) => {
  const [file, setFile] = useState(null)
  const [allFilesArr, setAllFilesArr] = useState([])
  const [userId, setuserId] = useRecoilState(userIdAtom);
  const [renamedFileName, setRenamedFileName] = useState('')
  const [viewStatistics, setViewStatistics] = useState(false)
  const [pages, setPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [showPdfPreviewModal, setShowPdfPreviewModal] = useState(false);
  const [selectedPdfFile, setSelectedPdfFile] = useState(null);

  const dispatch = useDispatch();

  const { loading: uploadLoading } = useSelector(state => state.uploadFile);
  const { loading: allFilesLoading } = useSelector(state => state.getAllFiles);

  console.log(allFilesLoading, 'allFilesLoading')
  // console.log(allFiles, 'allFiles')
  console.log(allFilesArr, 'allFilesArr')

  const getAllFiles = async () => {
    const result = await dispatch(getAllFilesAction(userId, deal?._id));
    if (result?.status === 1) {
      setAllFilesArr(result?.files)
    } else {
      setAllFilesArr([])
    }
  }

  useEffect(() => {
    dispatch(resetAllFilesAction())
    getAllFiles()
  }, [deal])

  const onDocumentComplete = (numPages) => {
    setPages(numPages);
  };

  const onDocumentError = (err) => {
    console.error("pdf viewer error:", err);
  };

  const uploadProps = {
    onRemove: () => {
      setFile(null)
    },
    beforeUpload: (inputFile) => {
      if (viewStatistics) setViewStatistics(false)
      const isPdfFormat = /\.pdf$/.test(inputFile.name)
      // && /\.sheet$/.test(inputFile.type)
      if (isPdfFormat) setFile(inputFile)
      else void console.log('Please upload in .pdf file format')
      return false
    },
    maxCount: 1,
    fileList: file === null ? [] : [file],
    accept: ".pdf"
  }

  const handleUpload = async (e) => {
    // e.stopPropagation()
    if (file) {
      let fileName = renamedFileName !== "" ? renamedFileName : file?.name;
      const formData = new FormData();
      formData.append('lead_id', deal?._id);
      formData.append('title', fileName);
      formData.append('files', file);

      const result = await dispatch(uploadFileAction(userId, formData));
      if (result?.status === 1) {
        setFile(null);
        toast.success(result?.message)
        getAllFiles()
      } else {
        toast.error(result?.message)
      }
    }
  }

  const handlePreview = (fileUrl) => {
    setSelectedPdfFile(fileUrl);
    setShowPdfPreviewModal(true);
};


const handleDownload = (fileUrl) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', 'document.pdf'); // You can customize the name of the downloaded file
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
  return (
    <div className="px-4 h-full">
      {/* Google Drive Card */}
      <div className="flex justify-center items-center bg-white  rounded-3xl  w-52 p-2">
        <FaRegFolder className="text-blue-500 mr-4" size={24} />
        <a href="https://googledrive.com" target="_blank" rel="noopener noreferrer" className="text-blue-700 font-semibold">
          googledrive.com
        </a>
      </div>
      <div className="w-full h-[85%] flex flex-col justify-between">
        <Spin spinning={allFilesLoading}>
          <div className='flex flex-wrap gap-8 max-h-[60vh] mt-7 overflow-y-auto'>
            {allFilesArr && allFilesArr?.map((file, index) => (
              // <a href={file?.fileUrl} className='h-72'>
              <div  className='h-72' >
                <div key={index} className='w-48 h-72 bg-white rounded-md overflow-hidden cursor-pointer' onClick={() => handlePreview(file?.fileUrl)}>
                  <PDF
                    file={file?.fileUrl}
                    onDocumentComplete={onDocumentComplete}
                    onDocumentError={onDocumentError}
                    page={1}
                    scale={scale}
                  />
                </div>
                <a href={file?.fileUrl} 
                  className={'inline-block text-custom-green cursor-pointer w-10'}
                >
                  <FaDownload/>
                </a>
              </div>
              //  </a>
            ))}
            <div className={`w-48 ${file ? "h-56" : "h-72"}`}>
              <Dragger {...uploadProps}>
                <span className="text-primary text-6xl flex justify-center">
                  <MdUploadFile size={40} color='#00A762' />
                </span>
                <p className="ant-upload-text font-semibold py-3">
                  Add another document
                </p>
              </Dragger>
              {file ?
                <div className='flex justify-between items-center w-full'>
                  <Input
                    placeholder='Rename the file'
                    className='w-[80%]'
                    suffix=".pdf"
                    onChange={e => setRenamedFileName(e.target.value)}
                  />
                  {uploadLoading ?
                    <div className='w-10 h-10 rounded-full overflow-hidden' >
                      <img src={animatedOk} width="40px" height="40px" alt='' className='bg-transparent rounded-full scale-[1.2]' />
                    </div> :
                    <div
                      className='w-10 h-10 flex justify-center items-center cursor-pointer'
                      onClick={() => handleUpload()}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                        <path fill="#c8e6c9" d="M44,24c0,11-9,20-20,20S4,35,4,24S13,4,24,4S44,13,44,24z"></path><polyline fill="none" stroke="#4caf50" stroke-miterlimit="10" stroke-width="4" points="14,24 21,31 36,16"></polyline>
                      </svg>
                    </div>
                  }
                </div> : null
              }
            </div>
          </div>
        </Spin>
        {showPdfPreviewModal && selectedPdfFile && (
                    <PdfPreviewModal
                        visible={showPdfPreviewModal}
                        onCancel={() => setShowPdfPreviewModal(false)}
                        pdf={selectedPdfFile}
                    />)}
        <div className='flex justify-between items-center w-full'>
          {/* <Input
            placeholder='Rename the file'
            className='w-60'
            suffix=".pdf"
            onChange={e => setRenamedFileName(e.target.value)}
          />
          <Button
            onClick={() => handleUpload()}
            loading={uploadLoading}
            className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
          >
            Upload
          </Button> */}
        </div>
      </div >
    </div >
  );
};

export default FilesTab;
