import { atom, useRecoilState } from "recoil";
import APIUrl from "../APIUrl";

export const isLoading = atom({
	key: "loading",
	default: false,
});

export const userIdAtom = atom({
	key: "user_id",
	default: null,
});

export const userName = atom({
	key: "user_name",
	default: "",
});

export const loginUser = async (email, password) => {
    
    try {
        const response = await fetch(`${APIUrl}/users/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        const data = await response.json();

        if (data.status === 1) {
            console.log("data --------- huy == " , data )
            return data;
        } else {
            
          alert(data.message || "Login failed");
          return data;
            
        }
    } catch (error) {
        console.error("Error during login:", error);
        throw error;
    }
};

export const signupMail = async (email, name , role , user_id) => {
    console.log("UserId ----- " , user_id  , "  Email -- " ,email , "  name " , name , " role " , role)

    try {
        const response = await fetch(`${APIUrl}/users/signupMail?user_id=${user_id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                name: name,
                role: role
            }),
        });


        const data = await response.json();

        if (data.status === 1) {
            console.log("data --------- huy == " , data )
            return data;
        } else {
            return data;
          alert(data.message || "Login failed");
            
        }
    } catch (error) {
        console.error("Error during login:", error);
        throw error;
    }
};

export const createPasswordNewrol = async (password , user_id) => {
    console.log("UserId ----- " , user_id  ,  "Password ----- " , password)
    try {
        const response = await fetch(`${APIUrl}/users/createPassword?user_id=${user_id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password: password
            }),
        });
        const data = await response.json();
        console.log(" create Password Data ---------  == " , data )
        if (data.status === 1) {
            console.log(" create Password Data ---------  == " , data )
            return data;
        } else 
            return data;
    } catch (error) {
        console.error("Error during login:", error);
        throw error;
    }
};