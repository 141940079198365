// Dashboard.js
import React, { useState } from "react";
import '../../../common/components/Calender.css';
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import SecondaryNavigation from "../../../common/components/SecondaryNavigation/SecondaryNavigation";
import ChildNavigation from "../../../common/components/SecondaryNavigation/ChildNavigation/ChildNavigation";
import { useNavigate } from "react-router-dom";
import { LabeledInput } from "../../../common/components/LeadComponent/CommonTextField";
import { MdEdit } from 'react-icons/md';
import ProfButton from '../../../data/profileupdate.png';
import { useSelector } from "react-redux";
import UserDetails from './UserDetails';

const AccountSettings = () => {
  const [selectedTab, setSelectedTab] = useState('Information');
  const { role } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    console.log("Tab ---------------  ", tab)
    setSelectedTab(tab);
  };

  const menuItems = role == 'operations' ?
    [
      "Dashboard",
      "Deals",
      "Submission Board",
      "Lender directory",
      "Compliance",
      "Commision portal"
    ]
    : [
      "Dashboard",
      "Leads",
      "Deals",
      "Submission Board",
      "Lender directory",
      "Compliance",
      "Commision portal"
    ];

  return (
    <div className="flex h-screen">
      <div className="flex space-x-24 w-80">
        <SecondaryNavigation items={menuItems} />
        <ChildNavigation items={["Account Settings", "Compliance Settings", "Email templates settings", "Sequences Settings", `${role == "admin" ? "Add new role" : ""}`, `${role == "admin" ? "Set funding target" : ""}`]} label={'Settings'} />
      </div>
      <main className="flex-1 bg-background-color p-6 pl-12 h-full flex flex-col ">
        <GoodMorningBar userName="Manish" />
        <div className="flex-grow mb-5 mt-8 overflow-y-auto">
          <div className="bg-white rounded-lg py-5 px-7 h-auto">
            <UserDetails />
          </div>
        </div>
      </main>
    </div>
  );
};

export default AccountSettings;
