// Dashboard.js
import React, { useEffect, useState } from "react";
import '../../../common/components/Calender.css';
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import { Table } from "lucide-react";
import ReusableTable from "../../../common/tables/Table";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ChildNavigation from "../../../common/components/SecondaryNavigation/ChildNavigation/ChildNavigation";
import SecondaryNavigation from "../../../common/components/SecondaryNavigation/SecondaryNavigation";
import DealNavigation from "../../../common/components/SecondaryNavigation/DealNavigation/DealNavigation";
import searchIcon from '../../../data/Group 536.png';
import { getAllDealsAction, getUserDealsAction } from '../../../redux/actions/dealsAction';
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../atom/authuserData/userdata';
import moment from 'moment/moment';
import { Spin } from 'antd';

const DealTable = () => {
  const [selectedTab, setSelectedTab] = useState('Information');
  const [searchText, setSearchText] = useState('');
  const [dealtableData, setDealTableData] = useState([]);

  const [userId, setuserId] = useRecoilState(userIdAtom);
  const { role } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { loading: allDealsLoading, deals } = useSelector(state => state.getUserDeals);

  useEffect(() => {
    if (role === 'agents') {
      dispatch(getUserDealsAction(userId));
    } else {
      dispatch(getAllDealsAction(userId));
    }
  }, []);

  useEffect(() => {
    let filteredData = []
    if (searchText !== '') {
      const trimmedSearchText = searchText.trim();
      filteredData = deals?.filter((deal) => {
        return (
          deal?.company_name?.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          // deal?.email?.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          // deal?.phone?.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          deal?.tag?.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          deal?.agent_name?.toLowerCase().includes(trimmedSearchText.toLowerCase())
        )
      })
    } else {
      filteredData = deals
    }

    const dealsData = []
    if (filteredData?.length > 0) {
      filteredData?.forEach((deal, index) => {
        dealsData.push({
          Key: index,
          id: deal?._id,
          status: deal?.status,
          queue: deal?.queue,
          company: deal?.company_name,
          agent: deal?.agent_name,
          date: deal?.createdAt,
          leadId: deal?.tag,
          email: deal?.email,
          phone: deal?.phone,
          city: deal?.city,
          street: deal?.street,
          lead_source: deal?.lead_source,
          openDeal: deal,
        })
      })
    }
    const sorteddealsData = dealsData?.sort((a, b) => moment(b.date) - moment(a.date))
    setDealTableData(sorteddealsData);
  }, [deals, searchText]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <div className={`flex items-center space-x-2 px-2 py-1  ${value === 'Declined' ? 'bg-declined-bgcolor' : value === "Approved" ? "bg-operation-green" : "bg-pending-background"} text-white rounded-2xl border w-28`}>
            <span className={`h-2 w-2 ${value === 'Declined' ? 'bg-red-600' : value === "Approved" ? 'bg-green-500' : 'bg-pendingText'} rounded-full`}></span>
            <span className={` ${value === 'Declined' ? 'text-red-600' : value === "Approved" ? "text-approved-text" : "text-pendingText"} font-semibold`}>{value}</span>
          </div>
        ),
      },
      {
        Header: 'Queue',
        accessor: 'queue',
        Cell: ({ value }) => (
          <div className="flex justify-center items-center px-3 py-1 font-semibold bg-operation-green text-white rounded-2xl border w-fit ">
            <span className="text-operaion-text-green capitalize">{value}</span>
          </div>
        ),
      },
      {
        Header: 'Agent',
        accessor: 'agent',

      },
      {
        Header: 'Company',
        accessor: 'company',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span>,
      },
      {
        Header: 'Lead ID',
        accessor: 'leadId',
      },
      {
        Header: 'Open deal',
        accessor: 'openDeal',
        Cell: ({ value }) => <FiEye onClick={(() => { navigate('/dealtabs', { state: { deal: value } }) })} alt="Open Deal" style={{ cursor: 'pointer', width: '20px', height: '20px' }} />,
      },
    ],
    []
  );

  return (
    <div className="flex h-screen">
      <DashboardTab />
      <main className="flex-1 bg-background-color p-6 h-full flex flex-col">
        <GoodMorningBar userName="Manish" />
        <div className="flex-grow overflow-y-auto mb-5">
          {allDealsLoading ?
            <div className='w-full pt-16 flex justify-center items-center' >
              <Spin />
            </div>
            :
            <div className='mt-4'>
              <div className='bg-white rounded-tl-xl rounded-tr-xl p-3'>
                <div className="flex items-center bg-background-color rounded-3xl p-2 h-10 w-full mr-3">
                  <img
                    src={searchIcon}
                    alt="Search Icon"
                    className="w-8 h-8 mr-2"
                  />
                  <input
                    type="text"
                    placeholder="Search information"
                    className="bg-background-color focus:outline-none pl-3 w-full"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
              <ReusableTable columns={columns} data={dealtableData} />
            </div>
          }
        </div>
      </main>
    </div>
  );
};

export default DealTable;
