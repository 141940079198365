import { SET_CHILD_TAB, SET_ROLE, SET_SELECTED_TAB } from '../types/authTypes';

export const setSelectedTab = (tab) => ({
  type: SET_SELECTED_TAB,
  payload: tab,
});

export const setChildSelectedTab = (childtab) => ({
  type: SET_CHILD_TAB,
  payload: childtab,
});

export const setRole = (role) => ({
  type: SET_ROLE,
  payload: role,
});
