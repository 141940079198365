
import React, { useEffect, useState } from "react";
import '../../../common/components/Calender.css';
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import { Table } from "lucide-react";
import ReusableTable from "../../../common/tables/Table";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import searchIcon from '../../../data/Group 536.png';
// import { getAllDealsAction, getUserDealsAction } from '../../../redux/actions/dealsAction';
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../atom/authuserData/userdata';
import moment from 'moment/moment';
import { Modal, Spin, Row, Col, Card, Select } from 'antd';
import { confirmPaidAction, getAllCommisionAction } from "../../../redux/actions/commisionAction";
import { toast } from "react-toastify";

const { Option } = Select;

const CommisionBoard = () => {
  const [selectedTab, setSelectedTab] = useState('Information');
  const [searchText, setSearchText] = useState('');
  // const [commissiontableData, setCommissionTableData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [userId, setuserId] = useRecoilState(userIdAtom);
  const { role } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { loading: allCommissionsLoading, allCommissions: commissions } = useSelector(state => state.getAllCommission);
  const { loading: confirmPaidLoading, success: confirmPaidSuccess } = useSelector(state => state.getPaid);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCommission, setSelectedCommission] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);

  useEffect(() => {
    dispatch(getAllCommisionAction(userId));
  }, [dispatch]);



  const commissiontableData = (commissions && Array.isArray(commissions))
    ? commissions?.filter(commission =>
      commission.companyName.toLowerCase().includes(searchText.toLowerCase())
    ).map(commissionData => ({
      date: commissionData?.date,
      companyName: commissionData,
      fundedAmount: commissionData?.agentFundingAmount,
      upsellPercentage: commissionData?.upsellPercentage,
      lender: commissionData?.lender,
      commission: commissionData?.commission,
      aup: commissionData?.aup,
      split: commissionData?.split,
      remaining: commissionData?.remaining,
      agent: commissionData?.agent,
      leader: commissionData?.leader,
      paid: commissionData,
    })) : [];

  // Ensure safe access when sorting
  const sortedcommissionsData = commissiontableData?.sort((a, b) => moment(b.date) - moment(a.date));


  const handlePaidClick = (commission) => {
    if (commission.paid === "No") {
      setSelectedCommission(commission);
      setIsModalVisible(true);
    }
  };

  const handlePaidChange = async (commissions, value) => {

    setIsUpdating(true);
    try {
      // API call to update the commission status
      await dispatch(confirmPaidAction(userId, commissions, value));
      toast.success("Commission status updated!");
      // Re-fetch the updated commission data
      dispatch(getAllCommisionAction(userId));
    } catch (error) {
      toast.error("Failed to update commission status.");
    } finally {
      setIsUpdating(false);
    }
  };



const options = [
  { value: 'Clawback', color: 'orange' },
  { value: 'Clawback Paid', color: 'green' },
  { value: 'Consolidation', color: 'grey' },
  { value: 'Yes', color: '#12459a' },
  { value: 'No', color: 'red' },
  { value: 'Paid By Lender', color: '#7d3c98' },
  { value: 'Defaulted Before CM', color: '#abebc6 ' },
  { value: 'HOLD', color: '#fdf2e9' },
  { value: 'Return', color: '#922b21' },
  { value: 'Pulled PSF Again', color: '#82e0aa' },
];

const columns = React.useMemo(
  () => [
    {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span>
      },
      {
        Header: 'Company Name',
        accessor: 'companyName',
        Cell: ({ value }) => (
          <span
          className="w-[120px] block cursor-pointer"
          onClick={() => handleCompanyClick(value)}
          >{value.companyName}</span> // Adjust width as needed
        ),
      },
      {
        Header: 'Funded Amount',
        accessor: 'fundedAmount',
        Cell: ({ value }) => (
          <span className="w-[120px] block">{value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value) : "-"}
          </span>
        ),
      },
      {
        Header: 'Upsell %',
        accessor: 'upsellPercentage',
        Cell: ({ value }) => (
          <span className="w-[60px] block">{value}%</span> // Adjust width as needed
        ),
      },
      {
        Header: 'Lender',
        accessor: 'lender',
        Cell: ({ value }) => (
          <span className="w-[150px] block">{value}</span> // Adjust width as needed
        ),
      },
      {
        Header: 'Commission',
        accessor: 'commission',
        Cell: ({ value }) => (
          <span>{value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value) : "-"}
          </span>
        ),
      },
      {
        Header: 'AUP %',
        accessor: 'aup',
        Cell: ({ value }) => (
          <span className="w-[50px] block">{value}%</span> // Adjust width as needed
        ),
      },
      {
        Header: 'Split',
        accessor: 'split',
        Cell: ({ value }) => (
          <span>{value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value) : "-"}
          </span>
        ),
      },
      {
        Header: 'Remaining',
        accessor: 'remaining',
        Cell: ({ value }) => (
          <span>{value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value) : "-"}
          </span>
        ),
      },
      {
        Header: 'Agent',
        accessor: 'agent',
        Cell: ({ value }) => (
          <span className="w-[120px] block">{value}</span> // Adjust width as needed
        ),
      },
      {
        Header: 'Paid',
        accessor: 'paid',
        // Cell: ({ value }) => (
          //   <span
          //     className={` ${value.paid === "No" ? "cursor-pointer text-red-700 bg-red-100 px-3 py-1 rounded-3xl" : "text-blue-700 bg-blue-100 px-3 py-1 rounded-3xl"}`}
          //     // onClick={() =>handlePaidClick(value)}
          //   >
          //     {value.paid}
          //   </span>
          // ),
          Cell: ({ row }) => {
            const commission = row.original; // Access the original commission data
            return (
              <Select
              defaultValue={commission.paid.paid}
              style={{ width: 120 }}
              onChange={(value) => handlePaidChange(commission.paid.lenderDealId, value)}
              >
              {options.map((option) => (
                <Option key={option.value} value={option.value}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: 12,
                      height: 12,
                      borderRadius: '50%',
                      backgroundColor: option.color,
                      marginRight: 8,
                    }}
                    />
                  {option.value}
                </Option>
              ))}
            </Select>
          );
        }
      },
      {
        Header: 'Leader',
        accessor: 'leader',
        Cell: ({ row }) => {
          const leaderValue = row.original.leader;
          return <span className="flex justify-center w-[120px] block">{leaderValue !== 'undefined undefined' && leaderValue !== null ? leaderValue : '-'}</span>;
        }
      },
      // {
        //   Header: 'Open deal',
        //   accessor: 'openDeal',
        //   Cell: ({ value }) => <FiEye onClick={(() => { navigate('/dealtabs', { state: { deal: value } }) })} alt="Open Deal" style={{ cursor: 'pointer', width: '20px', height: '20px' }} />,
        // },
      ],
      []
    );
    
    const handleCompanyClick = (companyName) => {
      setIsLoadingCompany(true);
      // const companyDetails = companyName.find(commission => commission.companyName === companyName);
      
      setSelectedCompany(companyName);
        setIsModalVisible(true);
        setIsLoadingCompany(false); 
    };


  return (
    <div className="flex h-screen">
      <DashboardTab />
      <main className="flex-1 bg-background-color p-6 h-full flex flex-col overflow-x-auto">
        <GoodMorningBar userName="Manish" />
        <div className="flex-grow overflow-y-auto mb-5">
          {allCommissionsLoading || confirmPaidLoading ?
            <div className='w-full pt-16 flex justify-center items-center' >
              <Spin />
            </div>
            :
            <div className='mt-4'>
              <div className='bg-white rounded-tl-xl rounded-tr-xl p-3'>
                <div className="flex items-center bg-background-color rounded-3xl p-2 h-10 w-full mr-3">
                  <img
                    src={searchIcon}
                    alt="Search Icon"
                    className="w-8 h-8 mr-2"
                  />
                  <input
                    type="text"
                    placeholder="Search information"
                    className="bg-background-color focus:outline-none pl-3 w-full"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
              <div className="table w-full table-fixed">
                <ReusableTable columns={columns} data={sortedcommissionsData} />
              </div>
            </div>
          }
        </div>
      </main>
      <Modal
        title={selectedCompany ? selectedCompany.companyName : 'Company Details'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={600}
        style={{ top: '10%' }}
        bodyStyle={{ padding: '24px', backgroundColor: '#f9f9f9' }}
      >
        {isLoadingCompany && allCommissionsLoading ? (
          <div className="flex justify-center items-center">
            <Spin />
          </div>
        ) : (
          selectedCompany ? (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card>
                  <Row gutter={16} className="mb-5">
                    <Col span={12}>
                      <strong>Funded Amount:</strong>
                      <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedCompany.agentFundingAmount)}</p>
                    </Col>
                    <Col span={12}>
                      <strong>Upsell Percentage:</strong>
                      <p>{selectedCompany.upsellPercentage}%</p>
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-5">
                    <Col span={12}>
                      <strong>Lender:</strong>
                      <p>{selectedCompany.lender}</p>
                    </Col>
                    <Col span={12}>
                      <strong>Commission:</strong>
                      <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedCompany.commission)}</p>
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-5">
                    <Col span={12}>
                      <strong>AUP:</strong>
                      <p>{selectedCompany.aup}%</p>
                    </Col>
                    <Col span={12}>
                      <strong>Split:</strong>
                      <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedCompany.split)}</p>
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-5">
                    <Col span={12}>
                      <strong>Remaining:</strong>
                      <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedCompany.remaining)}</p>
                    </Col>
                    <Col span={12}>
                      <strong>Agent:</strong>
                      <p>{selectedCompany.agent}</p>
                    </Col>
                  </Row>
                  <Row gutter={16} className="mb-5">
                    <Col span={12}>
                      <strong>Leader:</strong>
                      <p>{selectedCompany.leader !== 'undefined undefined' && selectedCompany.leader !== null ? selectedCompany.leader : '-'}</p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          ) : (
            <p>Data is not Fetched</p>
          )
        )}
      </Modal>
      {/* <Modal
        title="Confirmation"
        visible={isModalVisible}
        onOk={handleModalConfirm}
        onCancel={handleModalCancel}
        okButtonProps={{
          style: { backgroundColor: 'green', borderColor: 'green', color: 'white' },
        }}
      >
        <p>Are you sure you want to mark this commission as "Paid"?</p>
      </Modal> */}
    </div>
  );
};

export default CommisionBoard;
