import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from 'antd'
import moment from 'moment';
import searchIcon from '../../../data/Group 536.png';
import { FiEye } from "react-icons/fi";
import { getOldDealsAction } from '../../../redux/actions/dealsAction';
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../atom/authuserData/userdata';

const OldDealsTable = ({ status }) => {
  const [userId, setuserId] = useRecoilState(userIdAtom);
  const [searchText, setSearchText] = useState('');
  const [oldDealsTableData, setOldDealsTableData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const  location = useLocation();
  const [dealsData, setDealsData] = useState([]);

  const { loading: oldDealsLoading, oldDeals } = useSelector(state => state.getOldDeals);

  useEffect(() => {
    console.log('Fetching old deals...');
    if (location.state?.deals) {
      console.log('Using passed deals from location state:', location.state.deals);
      setDealsData(location.state.deals); // Use passed data
    } else if (!oldDeals || oldDeals.length === 0) {
      console.log('Dispatching action to fetch old deals');
      dispatch(getOldDealsAction(userId)).then(data => {
        console.log('Fetched old deals:', data);
        setDealsData(data); // Set fetched data to state
      });
    } else {
      console.log('Using cached data from Redux:', oldDeals);
      setDealsData(oldDeals); // Use cached data from Redux
    }
  }, [dispatch, location.state, oldDeals]);

  // useEffect(() => {
  //   dispatch(getOldDealsAction(userId));
  // }, []);

  useEffect(() => {
    let filteredData = []
    if (status && status !== "") {
      filteredData = oldDeals?.filter((deal) => deal?.status === status)
    } else {
      filteredData = oldDeals
    }

    if (searchText !== '') {
      const trimmedSearchText = searchText.trim();
      filteredData = filteredData?.filter((deal) => {
        return (
          deal?.company_name?.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          deal?.agent?.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          deal?.tag?.toLowerCase().includes(trimmedSearchText.toLowerCase())
        )
      })
    }

    const dealsData = []
    filteredData?.forEach((deal) => {
      dealsData.push({
        key: deal?._id,
        user_id: deal?.user_id,
        status: deal?.status,
        agent: deal?.agent,
        company_name: deal?.company_name,
        date: deal?.date,
        lead_source: deal?.lead_source,
        lead_id: deal?.lead_id,
        tag: deal?.tag,
        email: deal?.email,
        phone: deal?.phone,
        city: deal?.city,
        street: deal?.street,
        openDeal: deal
      })
    })

    setOldDealsTableData(dealsData)
  }, [oldDeals, searchText, status])

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <div className={`flex items-center space-x-2 px-2 py-1  ${(status === 'Declined' || status === 'DECLINED') ? 'bg-declined-bgcolor' : (status === "Approved" || status === "APPROVED") ? "bg-operation-green" : "bg-pending-background"} text-white rounded-2xl border w-28`}>
          <span className={`h-2 w-2 ${(status === 'Declined' || status === 'DECLINED') ? 'bg-red-600' : (status === "Approved" || status === "APPROVED") ? 'bg-green-500' : 'bg-pendingText'} rounded-full`}></span>
          <span className={` ${(status === 'Declined' || status === 'DECLINED') ? 'text-red-600' : (status === "Approved" || status === "APPROVED") ? "text-approved-text" : "text-pendingText"} font-semibold`}>{status}</span>
        </div>
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: "Company",
      dataIndex: "company_name",
      key: "company_name",
      sorter: (a, b) => a?.company_name?.localeCompare(b?.company_name)
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => <span>{moment(date).format('MM/DD/YYYY')}</span>,
      sorter: (a, b) => moment(a.date) - moment(b.date)
    },
    {
      title: "Lead Source",
      dataIndex: "lead_source",
      key: "lead_source",
      render: (lead_source) => <span>{lead_source ?? "-"}</span>,
    },
    {
      title: "Lead Id",
      dataIndex: "lead_id",
      key: "lead_id",
      render: (lead_id) => <span>{lead_id ?? "-"}</span>,
    },
    {
      title: "Lead Tag",
      dataIndex: "tag",
      key: "tag",
      render: (tag) => <span>{tag ?? "-"}</span>,
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    // },
    // {
    //   title: "Phone",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    // {
    //   title: "City",
    //   dataIndex: "city",
    //   key: "city",
    // },
    // {
    //   title: "Street",
    //   dataIndex: "street",
    //   key: "street",
    // },
    {
      title: "Open deal",
      dataIndex: "openDeal",
      key: "openDeal",
      render: (deal) => (
        <FiEye onClick={(() => { navigate('/dealtabs', { state: { deal: deal, fromOldTable: true } }) })} alt="Open Deal" style={{ cursor: 'pointer', width: '20px', height: '20px' }} />
      )
    }
  ];

  return (
    <div className="flex-grow overflow-y-scroll mb-5">
      <div className='mt-4 w-full'>
        <div className='bg-white rounded-tl-xl rounded-tr-xl p-3'>
          <div className="flex items-center bg-background-color rounded-3xl p-2 h-10 w-full mr-3">
            <img
              src={searchIcon}
              alt="Search Icon"
              className="w-8 h-8 mr-2"
            />
            <input
              type="text"
              placeholder="Search information"
              className="bg-background-color focus:outline-none pl-3 w-full"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <Table
          scroll={{
            x: 800,
          }}
          loading={oldDealsLoading}
          columns={columns}
          dataSource={oldDealsTableData} />
      </div>
    </div>
  )
}

export default OldDealsTable