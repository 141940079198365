
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPage from '../auth/Login';
import IndividualDeal from '../screens/tabScreens/DealsScreen/IndividualDeal';
import Dashboard from '../screens/tabScreens/DashboardScreen';
import LenderPage from '../screens/tabScreens/DealsScreen/InduvidualDealTabs/LenderPage';
import LeadTable from '../screens/tabScreens/LeadScreen/LeadTable';
import LeadScreen from '../screens/tabScreens/LeadScreen/LeadScreen';
import DealTable from '../screens/tabScreens/DealsScreen/DealTable';
import SubmissionTable from '../screens/tabScreens/SubmissionBoard/SubmissionTable';
import LenderDirectoryTable from '../screens/tabScreens/LenderDirectory/LenderDirectoryTable';
import CommisionPortalTable from '../screens/tabScreens/CommisionPortal/CommisioPortalTable';
import Compliance from '../screens/tabScreens/Compliance/Compliance';
import CommisionPortalForm from '../screens/tabScreens/CommisionPortal/CommisionPortalForm';
import AccountSettings from '../screens/tabScreens/Settings/Account Settings';
import ComplianceSettings from '../screens/tabScreens/Settings/ComplianceSetting';
import EmailTemplateSettings from '../screens/tabScreens/Settings/EmailTemplateSettings';
import SequenceSettings from '../screens/tabScreens/Settings/SequencesSettings';
import BusinessAnalytics from '../screens/AdminScreens/BusinessAnalytics/BusinessAnalytics';
import SubmissionChart from '../screens/AdminScreens/SubmissionChart/SubmissionChart';
import { useSelector } from 'react-redux';
import Leads from '../screens/AdminScreens/Leads/Leads';
import CommisionBoard from '../screens/AdminScreens/CommisionBoard/CommisionBoard';
import Reports from '../screens/AdminScreens/Reports/Reports';
import AddNewLender from '../screens/AdminScreens/AddNewLender/AddNewLender';
import CreatePassword from '../auth/createPassword';
import AddNewRole from '../screens/tabScreens/Settings/AddNewRole';
import ProtectedRoutes from '../utils/ProtectedRoutes';
import AddLead from '../screens/tabScreens/LeadScreen/AddLead';
import SetFundingTarget from '../screens/tabScreens/Settings/SetFundingTarget';
import AddLeader from '../screens/tabScreens/Settings/addNewLeader';

export default function Router() {
  const { role } = useSelector((state) => state.auth);
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route element={<ProtectedRoutes />}>

        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path="/leads" element={role === 'admin' ? <Leads /> : <LeadTable />} /> */}
        <Route path="/leads" element={<LeadTable />} />
        <Route path="/add-lead" element={<AddLead />} />
        <Route path="/leadstabs" element={<LeadScreen />} />
        <Route path="/deals" element={<DealTable />} />
        <Route path="/dealtabs" element={<IndividualDeal />} />
        <Route path="/submission-board" element={<SubmissionTable />} />
        <Route path="/commision-portal" element={<CommisionPortalTable />} />
        <Route path="/lender-directory" element={<LenderDirectoryTable />} />
        <Route path="/compliance" element={<Compliance />} />
        <Route path="/createCommision" element={<CommisionPortalForm />} />
        <Route path="/settings" element={<AccountSettings />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route path="/compliance-settings" element={<ComplianceSettings />} />
        <Route path="/email-templates settings" element={<EmailTemplateSettings />} />
        <Route path="/sequences-settings" element={<SequenceSettings />} />
        <Route path="/business-analytics" element={<BusinessAnalytics />} />
        <Route path="/submission-chart" element={<SubmissionChart />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/commision-board" element={<CommisionBoard />} />
        <Route path="/addLender" element={<AddNewLender />} />
        <Route path="/add-new role" element={<AddNewRole />} />
        <Route path="/add-leader" element={<AddLeader />} />
        <Route path="/set-funding target" element={<SetFundingTarget />} />
      </Route>

      <Route path="/createPassword" element={<CreatePassword />} />

    </Routes>
  )
}
