import axios from 'axios';
import { axiosGet, axiosPost } from '../axios';
import {
  ADD_NEW_LENDER_FAIL,
  ADD_NEW_LENDER_REQUEST,
  ADD_NEW_LENDER_SUCCESS,
  ADD_NEW_LENDER_TO_DEAL_FAIL,
  ADD_NEW_LENDER_TO_DEAL_REQUEST,
  ADD_NEW_LENDER_TO_DEAL_SUCCESS,
  GET_ALL_LENDERS_FAIL,
  GET_ALL_LENDERS_REQUEST,
  GET_ALL_LENDERS_SUCCESS,
  GET_DEALS_BY_LENDER_FAIL,
  GET_DEALS_BY_LENDER_REQUEST,
  GET_DEALS_BY_LENDER_SUCCESS,
  GET_DELETE_LENDERS_FAIL,
  GET_DELETE_LENDERS_REQUEST,
  GET_DELETE_LENDERS_SUCCESS,
} from '../types/lenderTypes';

export const addNewLenderAction = (userId, lenderData) => async (dispatch) => {
  console.log("Lender Data",lenderData)
  // for (let [key, value] of lenderData.entries()) {
  //   console.log(key, value);
  // }
  try {
    dispatch({ type: ADD_NEW_LENDER_REQUEST });

    // const { data } = await axiosPost(`lenders/createLender?user_id=${userId}`, lenderData);
    const { data } = await axios.post(`http://localhost:3000/.netlify/functions/server/lenders/createLender?user_id=${userId}`, lenderData);
    dispatch({ type: ADD_NEW_LENDER_SUCCESS, payload: data.lender });
    return data;
  } catch (error) {
    dispatch({
      type: ADD_NEW_LENDER_FAIL,
      payload: error.response && error.response.data ? error.response.data : error.message,
    });
  }
}

export const getAllLendersAction = (userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_LENDERS_REQUEST });

    const { data } = await axiosGet(`lenders/getAllLenders?user_id=${userId}`);

    dispatch({ type: GET_ALL_LENDERS_SUCCESS, payload: data?.lenders });
    return data;
  } catch (error) {
    dispatch({
      type: GET_ALL_LENDERS_FAIL,
      payload: error.response && error.response.data ? error.response.data : error.message,
    });
  }
}

export const addNewLenderToDealAction = (userId, dealId, lenderId) => async (dispatch) => {
  try {
    dispatch({ type: ADD_NEW_LENDER_TO_DEAL_REQUEST });

    const { data } = await axiosPost(`lenders/addLenderToDeal?user_id=${userId}`, {
      deal_id: dealId,
      lender_id: lenderId
    });

    dispatch({ type: ADD_NEW_LENDER_TO_DEAL_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: ADD_NEW_LENDER_TO_DEAL_FAIL,
      payload: error.response && error.response.data ? error.response.data : error.message,
    });
  }
}

export const getDealsByLenderAction = (userId, dealId) => async (dispatch) => {
  try {
    dispatch({ type: GET_DEALS_BY_LENDER_REQUEST });

    const { data } = await axiosGet(`lenders/getDealLenders?user_id=${userId}&deal_id=${dealId}`);

    dispatch({ type: GET_DEALS_BY_LENDER_SUCCESS, payload: data?.lenders });
    return data;
  } catch (error) {
    dispatch({
      type: GET_DEALS_BY_LENDER_FAIL,
      payload: error?.response?.data ? error.response.data : error.message,
    });
  }
}
export const getApprovedDealsByLenderAction = (userId, dealId) => async (dispatch) => {
  try {
    dispatch({ type: GET_DEALS_BY_LENDER_REQUEST });

    const { data } = await axiosGet(`lenders/approvedDealLenders?user_id=${userId}&deal_id=${dealId}`);
    console.log(data, 'data');

    dispatch({ type: GET_DEALS_BY_LENDER_SUCCESS, payload: data?.lenders });
    return data;
  } catch (error) {
    dispatch({
      type: GET_DEALS_BY_LENDER_FAIL,
      payload: error?.response?.data ? error.response.data : error.message,
    });
  }
}

// delete lender
export const getDeleteLenderAction = (userId, lenderId) => async (dispatch) => {
  try {
    dispatch({ type: GET_DELETE_LENDERS_REQUEST });
    const { data } = await axiosGet(`lenders/deleteLender?user_id=${userId}&lender_id=${lenderId}`);
    dispatch({ type: GET_DELETE_LENDERS_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_DELETE_LENDERS_FAIL,
      payload: error?.response?.data ? error.response.data : error.message,
    });
  }
}