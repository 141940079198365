import { Button, Col, Input, Row } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { userIdAtom } from '../../../atom/authuserData/userdata';
import { updateLeadAction } from '../../../redux/actions/leadsActions';
import "../../../App.css"
import { toast } from 'react-toastify';

const schema = yup
  .object({
    companyName: yup
      .string()
      .max(100)
      .required("Enter company name"),
    // .matches(/^[a-zA-Z\s]+$/, "First Name should only contain alphabets"),
    leadTag: yup
      .string()
      .max(100)
      .required("Enter lead tag"),
    // .matches(/^[a-zA-Z\s]*$/, "Middle Name should only contain alphabets"),
    firstName: yup
      .string()
      .max(100)
      .required("Enter first name"),
    // .matches(/^[a-zA-Z\s]+$/, "Last Name should only contain alphabets"),
    lastName: yup
      .string()
      .max(100)
      .required("Enter Last Name"),
    // .matches(/^[a-zA-Z\s]+$/, "Last Name should only contain alphabets"),
    email: yup.string().email().matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, "Invalid Email").required("Email is required"),
    phoneNumber: yup.string().required("Mobile Number Must Be 10 Digits"),
    city: yup.string().required("City is required"),
    street: yup.string().required("Street is required"),
    leadSource: yup.string().required("Lead Source is required"),
  })
  .required()

const EditLead = ({ inputDisabled, setInputDisabled }) => {
  const [userId, setuserId] = useRecoilState(userIdAtom);

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const { lead } = location.state;

  const { loading: updateLeadLoading } = useSelector(state => state.updateLead)

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      companyName: lead?.company_name,
      firstName: lead?.firstName,
      lastName: lead?.lastName,
      email: lead?.email,
      phoneNumber: lead?.phone,
      city: lead?.city,
      street: lead?.street,
      leadTag: lead?.tag,
      leadSource: "743",
    },
    resolver: yupResolver(schema)
  })

  const formatPhoneNumber = (phoneNumber) => {
    // Remove any non-digit characters
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  
    return phoneNumber; // Return the original if it doesn't match the expected format
  };

  const onSubmit = async data => {
    const { companyName, leadTag, firstName, lastName, phoneNumber, email, city, street, leadSource } = data
    const leadData = {
      "lead_id": lead?._id,
      "company_name": companyName,
      "firstname": firstName,
      "lastname": lastName,
      "email": email,
      "phone": phoneNumber,
      "city": city,
      "street": street,
      "leadSource": leadSource,
      "tag": leadTag
    }
    const result = await dispatch(updateLeadAction(userId, leadData))
    if (result?.status === 1) {
      toast.success("Lead edited successfully")
      // navigate("/leads")
      setInputDisabled(true)
    } else {
      toast.error(result?.message)
    }
  }

  return (
    <form className="pt-4 flex flex-col justify-between h-full" onSubmit={handleSubmit(onSubmit)}>
      {/* <Spin spinning={true}> */}
      <Row gutter={16}>
        <Col className="mb-4" xs={24} md={12} lg={12}>
          <label className="mb-1 font-medium" htmlFor="input-companyName">
            Company Name
            {/* <span className="text-red-500">*</span> */}
          </label>
          <Controller
            name="companyName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                size="large"
                disabled={inputDisabled}
                id="input-companyName"
                placeholder='Enter Company Name'
                value={field.value}
                status={errors.companyName ? "error" : undefined}
                {...field}
              />
            )}
          />
          {errors.companyName ? (
            <small className="text-red-500 capitalize">{errors.companyName.message}</small>
          ) : null}
        </Col>

        <Col className="mb-4" xs={24} md={12} lg={12}>
          <label className="mb-1 font-medium" htmlFor="input-leadTag">
            Lead Tag
          </label>
          <Controller
            name="leadTag"
            control={control}
            render={({ field }) => (
              <Input
                size="large"
                id="input-leadTag"
                disabled={inputDisabled}
                placeholder='Enter Lead Tag'
                value={field.value}
                status={errors.leadTag ? "error" : undefined}
                {...field}
              />
            )}
          />
          {errors.leadTag ? (
            <small className="text-red-500 capitalize">{errors.leadTag.message}</small>
          ) : null}
        </Col>
        <Col className="mb-4" xs={24} md={12} lg={12}>
          <label className="mb-1 font-medium" htmlFor="input-firstName">
            First Name
            {/* <span className="text-red-500">*</span> */}
          </label>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                size="large"
                id="input-firstName"
                disabled={inputDisabled}
                placeholder='Enter First Name'
                value={field.value}
                status={errors.firstName ? "error" : undefined}
                {...field}
              />
            )}
          />
          {errors.firstName ? (
            <small className="text-red-500 capitalize">{errors.firstName.message}</small>
          ) : null}
        </Col>
        <Col className="mb-4" xs={24} md={12} lg={12}>
          <label className="mb-1 font-medium" htmlFor="input-lastName">
            Last Name
            {/* <span className="text-red-500">*</span> */}
          </label>
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                size="large"
                id="input-lastName"
                disabled={inputDisabled}
                placeholder='Enter Last Name'
                value={field.value}
                status={errors.lastName ? "error" : undefined}
                {...field}
              />
            )}
          />
          {errors.lastName ? (
            <small className="text-red-500 capitalize">{errors.lastName.message}</small>
          ) : null}
        </Col>
        <Col className="mb-4" xs={24} md={12} lg={12}>
          <label className="mb-1 font-medium" htmlFor="input-phoneNumber">
            Phone Number
            {/* <span className="text-red-500">*</span> */}
          </label>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: true }}
            render={({ field }) =>
              <Input
                size="large"
                id="input-phoneNumber"
                disabled={inputDisabled}
                placeholder='Enter Phone Number'
                value={formatPhoneNumber(field.value)}
                onChange={e => {
                  const formattedValue = formatPhoneNumber(e.target.value); // Format the input value
                  field.onChange(formattedValue); // Update the form state with the formatted value
                }}
                status={errors.phoneNumber ? "error" : undefined}
              />}
          />
          {errors.phoneNumber ? (
            <small className="text-red-500 capitalize">{errors.phoneNumber.message}</small>
          ) : null}
        </Col>

        <Col className="mb-4" xs={24} md={12} lg={12}>
          <label className="mb-1 font-medium" htmlFor="input-email">
            Email
            {/* <span className="text-red-500">*</span> */}
          </label>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                size="large"
                type="email"
                id="input-email"
                disabled={inputDisabled}
                placeholder='Enter Email'
                value={field.value}
                status={errors.email ? "error" : undefined}
                {...field}
              />
            )}
          />
          {errors.email ? (
            <small className="text-red-500 capitalize">{errors.email.message}</small>
          ) : null}
        </Col>

        <Col className="mb-4" xs={24} md={12} lg={12}>
          <label className="mb-1 font-medium" htmlFor="input-city">
            City
            {/* <span className="text-red-500">*</span> */}
          </label>
          <Controller
            name="city"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                size="large"
                id="input-city"
                disabled={inputDisabled}
                placeholder='Enter City'
                value={field.value}
                status={errors.city ? "error" : undefined}
                {...field}
                onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
              />
            )}
          />
          {errors.city ? (
            <small className="text-red-500 capitalize">{errors.city.message}</small>
          ) : null}
        </Col>

        <Col className="mb-4" xs={24} md={12} lg={12}>
          <label className="mb-1 font-medium" htmlFor="input-street">
            Street
            {/* <span className="text-red-500">*</span> */}
          </label>
          <Controller
            control={control}
            name="street"
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                size="large"
                id="input-street"
                disabled={inputDisabled}
                placeholder='Enter Street'
                value={field.value}
                status={errors.street ? "error" : undefined}
                {...field}
              />
            )}
          />
          {errors.street ? (
            <small className="text-red-500 capitalize">{errors.street.message}</small>
          ) : null}
        </Col>
        <Col className="mb-4" xs={24} md={12} lg={12}>
          <label className="mb-1 font-medium" htmlFor="input-leadSource">
            Lead Source
          </label>
          <Controller
            control={control}
            name="leadSource"
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                size="large"
                id="input-leadSource"
                disabled={inputDisabled}
                placeholder='Enter Lead Source'
                value={field.value}
                status={errors.leadSource ? "error" : undefined}
                {...field}
              />
            )}
          />
          {errors.leadSource ? (
            <small className="text-red-500 capitalize">{errors.leadSource.message}</small>
          ) : null}
        </Col>

      </Row>
      {/* </Spin> */}
      {!inputDisabled &&
        <div className='flex justify-end items-center gap-4 mb-5'>
          <Button
            size='large'
            htmlType="submit"
            loading={updateLeadLoading}
            className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
          >
            Submit
          </Button>
        </div>
      }
    </form>
  )
}

export default EditLead