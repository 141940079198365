import { Button, Col, DatePicker, Input, Row, Spin } from 'antd'
import * as yup from "yup"
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup"
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useRecoilState } from 'recoil'
import { userIdAtom, userName } from '../../../atom/authuserData/userdata'
import { useNavigate } from 'react-router-dom'
import ProfButton from '../../../data/profileupdate.png';
import { useEffect, useState } from 'react'
import "../../../App.css"
import { getUserDetails, updateUserDetails } from '../../../redux/actions/userAction'
import moment from 'moment/moment'

const schema = yup
  .object({
    firstname: yup
      .string()
      .max(100)
      .required("Enter first name"),
    // .matches(/^[a-zA-Z\s]+$/, "Last Name should only contain alphabets"),
    lastname: yup
      .string()
      .max(100)
      .required("Enter Last Name"),
    // .matches(/^[a-zA-Z\s]+$/, "Last Name should only contain alphabets"),
    date: yup.string().required("Date is required"),
    phone: yup.string().required("Mobile Number Must Be 10 Digits"),
    email: yup.string().email().matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, "Invalid Email").required("Email is required"),
    address: yup.string().required("Address is required"),
  })
  .required()

const defaultValues = {
  firstname: "",
  lastname: "",
  dob: "",
  phone: "",
  email: "",
  address: "",
  date: ""
}

const UserDetails = () => {
  const [userId, setuserId] = useRecoilState(userIdAtom);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [userDetails, setUserDetails] = useState({})
  const [name, setName] = useRecoilState(userName);

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loading: userLoading, user } = useSelector(state => state.getUserDetails)
  const { loading: updateLoading } = useSelector(state => state.updateUserDetails)

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    dispatch(getUserDetails(userId))
  }, [userId])

  useEffect(() => {
    if (user) {
      setUserDetails(user)
      setValue("firstname", user?.firstName)
      setValue("lastname", user?.lastName)
      setValue("dob", moment(user?.dob))
      setValue("phone", user?.phone)
      setValue("email", user?.email)
      setValue("address", user?.address)
      setValue("date", moment(user?.createdAt))
      setName(user?.firstName)
    }
  }, [user])

  const onSubmit = async data => {
    const { firstname, lastname, phone, email, address, dob } = data
    const userDetail = {
      "firstname": firstname,
      "lastname": lastname,
      "email": email,
      "phone": phone,
      "Address": address,
      "dob": dob,
    }
    const result = await dispatch(updateUserDetails(userId, userDetail))
    if (result?.status === 1) {
      toast.success(result.message)
      dispatch(getUserDetails(userId))
    } else {
      toast.error(result?.message)
    }
  }

  return (
    <>
      {userLoading ?
        <div className='w-full flex justify-center items-center mt-8'>
          <Spin />
        </div>
        :
        <form className="pt-4 flex flex-col justify-between h-full" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="flex items-center gap-2 mb-6">
              <div className="relative inline-block mr-8">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYPgwYYhHGntkkaWUE7g3jGjMkqSkSYbGq4g&s"
                  alt="User Avatar"
                  className="w-28 h-28 rounded-full border-2 border-custom-green"  // Adjust size and border color
                />
                <div className="absolute bottom-0 right-0  p-1 ">
                  {/* <img src="../" className="text-green-500" size={20} />   */}
                  <img
                    src={ProfButton}
                    alt="ProfileImage"
                    className="object-cover h-8  mt-1"
                  />
                </div>
              </div>
              <div className='w-[65%] flex justify-start items-center'>
                <Col className="mb-4" xs={24}>
                  <label className="mb-1 font-medium" htmlFor="input-dob">
                    Birthday
                  </label>
                  <Controller
                    name="dob"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        size="large"
                        id="input-dob"
                        format={"DD-MMM-YYYY"}
                        value={field.value}
                        disabled={inputDisabled}
                        status={errors.dob ? "error" : undefined}
                        className="w-full"
                        {...field}
                      />
                    )}
                  />
                  {errors.dob ? (
                    <small className="text-red-500 capitalize">{errors.dob.message}</small>
                  ) : null}
                </Col>
              </div>
              <div
                className='mt-1 h-10 px-14 p-1 rounded-lg text-white bg-custom-green font-semibold flex justify-center items-center cursor-pointer'
                onClick={() => setInputDisabled(!inputDisabled)}
              >
                Edit
              </div>
            </div>
            <Row gutter={16}>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-firstname">
                  Agent First Name
                </label>
                <Controller
                  name="firstname"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-firstname"
                      disabled={inputDisabled}
                      value={field.value}
                      placeholder='Enter first name'
                      status={errors.firstname ? "error" : undefined}
                      {...field}
                    />
                  )}
                />
                {errors.firstname ? (
                  <small className="text-red-500 capitalize">{errors.firstname.message}</small>
                ) : null}
              </Col>

              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-date">
                  Date
                </label>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      size="large"
                      id="input-date"
                      format={"MM/DD/YYYY"}
                      value={field.value}
                      disabled={true}
                      status={errors.date ? "error" : undefined}
                      className="w-full"
                      {...field}
                    />
                  )}
                />
                {errors.date ? (
                  <small className="text-red-500 capitalize">{errors.date.message}</small>
                ) : null}
              </Col>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-lastname">
                  Agent Last Name
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="lastname"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-lastname"
                      disabled={inputDisabled}
                      value={field.value}
                      placeholder='Enter last name'
                      status={errors.lastname ? "error" : undefined}
                      {...field}
                    />
                  )}
                />
                {errors.lastname ? (
                  <small className="text-red-500 capitalize">{errors.lastname.message}</small>
                ) : null}
              </Col>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-phone">
                  Phone Number
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="phone"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <Input
                      size="large"
                      id="input-phone"
                      disabled={inputDisabled}
                      value={field.value}
                      placeholder='Enter phone number'
                      {...field}
                      status={errors.phone ? "error" : undefined}
                    />}
                />
                {errors.phone ? (
                  <small className="text-red-500 capitalize">{errors.phone.message}</small>
                ) : null}
              </Col>

              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-email">
                  Email
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      type="email"
                      id="input-email"
                      value={field.value}
                      disabled={inputDisabled}
                      placeholder='Enter Email'
                      status={errors.email ? "error" : undefined}
                      {...field}
                    />
                  )}
                />
                {errors.email ? (
                  <small className="text-red-500 capitalize">{errors.email.message}</small>
                ) : null}
              </Col>

              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-address">
                  Address
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="address"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-address"
                      disabled={inputDisabled}
                      value={field.value}
                      placeholder='Enter City'
                      status={errors.address ? "error" : undefined}
                      {...field}
                      onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                    />
                  )}
                />
                {errors.address ? (
                  <small className="text-red-500 capitalize">{errors.address.message}</small>
                ) : null}
              </Col>
              {/* <Col className="mb-4" xs={24} md={12} lg={12}>
          <label className="mb-1 font-medium" htmlFor="input-password">
            Password
          </label>
          <Controller
            control={control}
            name="password"
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                type='password'
                size="large"
                id="input-password"
                disabled={inputDisabled}
                placeholder='Enter password'
                status={errors.password ? "error" : undefined}
                {...field}
              />
            )}
          />
          {errors.password ? (
            <small className="text-red-500 capitalize">{errors.password.message}</small>
          ) : null}
        </Col> */}

            </Row>
          </div>
          {!inputDisabled &&
            <div className='flex justify-end items-center gap-4 mb-5'>
              <Button
                size='large'
                htmlType="submit"
                loading={updateLoading}
                disabled={updateLoading}
                className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
              >
                Submit
              </Button>
            </div>
          }
        </form>
      }
    </>
  )
}

export default UserDetails
