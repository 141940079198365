import { Button, Col, Input, Row } from 'antd'
import DashboardTab from '../../../common/components/DashboardTab/DashboardTabs'
import GoodMorningBar from '../../../common/components/GoodmorningBar'
import * as yup from "yup"
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup"
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { addLeadAction } from '../../../redux/actions/leadsActions'
import { useRecoilState } from 'recoil'
import { userIdAtom } from '../../../atom/authuserData/userdata'
import { useNavigate } from 'react-router-dom'

const schema = yup
  .object({
    companyName: yup
      .string()
      .max(100)
      .required("Enter company name"),
    // .matches(/^[a-zA-Z\s]+$/, "First Name should only contain alphabets"),
    leadTag: yup
      .string()
      .max(100)
      .required("Enter lead tag"),
    // .matches(/^[a-zA-Z\s]*$/, "Middle Name should only contain alphabets"),
    firstName: yup
      .string()
      .max(100)
      .required("Enter first name"),
    // .matches(/^[a-zA-Z\s]+$/, "Last Name should only contain alphabets"),
    lastName: yup
      .string()
      .max(100)
      .required("Enter Last Name"),
    // .matches(/^[a-zA-Z\s]+$/, "Last Name should only contain alphabets"),
    email: yup.string().email().matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, "Invalid Email").required("Email is required"),
    phoneNumber: yup.string().required("Mobile Number Must Be 10 Digits"),
    city: yup.string().required("City is required"),
    street: yup.string().required("Street is required"),
    leadSource: yup.string().required("Lead Source is required"),
  })
  .required()

const defaultValues = {
  companyName: "",
  leadTag: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  city: "",
  street: "",
  leadSource: "",
}

const AddLead = () => {
  const [userId, setuserId] = useRecoilState(userIdAtom);
  const { loading: addLeadLoading } = useSelector(state => state.addLead);

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = async data => {
    const { companyName, leadTag, firstName, lastName, phoneNumber, email, city, street, leadSource } = data
    const leadData = {
      "cName": companyName,
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "phone": phoneNumber,
      "city": city,
      "street": street,
      "leadSource": leadSource,
      "tag": leadTag
    }
    const result = await dispatch(addLeadAction(userId, leadData))
    if (result?.status === 1) {
      toast.success("Lead added successfully")
      navigate("/leads")
    } else {
      toast.error(result?.message)
    }
  }

  const formatPhoneNumber = (number) => {
    const cleaned = ('' + number).replace(/\D/g, ''); // Remove non-numeric characters
    const match = cleaned.match(/(\d{3})(\d{3})(\d{4})/); // Format as (XXX) XXX-XXXX
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return number; // Return the number as-is if it doesn't match the expected pattern
  };

  const handleDraft = () => {
    toast.success("Saved as draft")
  }

  return (
    <div className="flex h-screen bg-black">
      <DashboardTab />
      <main className="flex-1 bg-background-color p-6 h-full flex flex-col w-full">
        <GoodMorningBar userName="Manish" />
        <div
          className='w-[97%] my-6 bg-white p-6 rounded-lg m-5 h-full'
        >
          <h1 className='text-lg font-medium'>Customer Information</h1>
          <form className="pt-4 flex flex-col justify-between h-full" onSubmit={handleSubmit(onSubmit)}>
            {/* <Spin spinning={true}> */}
            <Row gutter={16}>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-companyName">
                  Company Name
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="companyName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-companyName"
                      placeholder='Enter Company Name'
                      status={errors.companyName ? "error" : undefined}
                      {...field}
                    // onChange={e => field.onChange(e)}
                    />
                  )}
                />
                {errors.companyName ? (
                  <small className="text-red-500 capitalize">{errors.companyName.message}</small>
                ) : null}
              </Col>

              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-leadTag">
                  Lead Tag
                </label>
                <Controller
                  name="leadTag"
                  control={control}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-leadTag"
                      placeholder='Enter Lead Tag'
                      status={errors.leadTag ? "error" : undefined}
                      {...field}
                    // onChange={e => field.onChange(e)}
                    />
                  )}
                />
                {errors.leadTag ? (
                  <small className="text-red-500 capitalize">{errors.leadTag.message}</small>
                ) : null}
              </Col>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-firstName">
                  First Name
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-firstName"
                      placeholder='Enter First Name'
                      status={errors.firstName ? "error" : undefined}
                      {...field}
                    // onChange={e => field.onChange(e)}
                    />
                  )}
                />
                {errors.firstName ? (
                  <small className="text-red-500 capitalize">{errors.firstName.message}</small>
                ) : null}
              </Col>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-lastName">
                  Last Name
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-lastName"
                      placeholder='Enter Last Name'
                      status={errors.lastName ? "error" : undefined}
                      {...field}
                    // onChange={e => field.onChange(e)}
                    />
                  )}
                />
                {errors.lastName ? (
                  <small className="text-red-500 capitalize">{errors.lastName.message}</small>
                ) : null}
              </Col>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-phoneNumber">
                  Phone Number
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <Input
                      size="large"
                      id="input-phoneNumber"
                      placeholder='Enter Phone Number'
                      value={formatPhoneNumber(field.value)} // Format the value before displaying
                      onChange={(e) => field.onChange(formatPhoneNumber(e.target.value))} // Format on change
                      // {...field}
                      status={errors.phoneNumber ? "error" : undefined}
                    />}
                />
                {errors.phoneNumber ? (
                  <small className="text-red-500 capitalize">{errors.phoneNumber.message}</small>
                ) : null}
              </Col>

              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-email">
                  Email
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      type="email"
                      id="input-email"
                      placeholder='Enter Email'
                      status={errors.email ? "error" : undefined}
                      {...field}
                    />
                  )}
                />
                {errors.email ? (
                  <small className="text-red-500 capitalize">{errors.email.message}</small>
                ) : null}
              </Col>

              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-city">
                  City
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  name="city"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-city"
                      placeholder='Enter City'
                      status={errors.city ? "error" : undefined}
                      {...field}
                      onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                    />
                  )}
                />
                {errors.city ? (
                  <small className="text-red-500 capitalize">{errors.city.message}</small>
                ) : null}
              </Col>

              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-street">
                  Street
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <Controller
                  control={control}
                  name="street"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-street"
                      placeholder='Enter Street'
                      status={errors.street ? "error" : undefined}
                      {...field}
                    />
                  )}
                />
                {errors.street ? (
                  <small className="text-red-500 capitalize">{errors.street.message}</small>
                ) : null}
              </Col>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-leadSource">
                  Lead Source
                </label>
                <Controller
                  control={control}
                  name="leadSource"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-leadSource"
                      placeholder='Enter Lead Source'
                      status={errors.leadSource ? "error" : undefined}
                      {...field}
                    />
                  )}
                />
                {errors.leadSource ? (
                  <small className="text-red-500 capitalize">{errors.leadSource.message}</small>
                ) : null}
              </Col>

            </Row>
            {/* </Spin> */}
            <div className='flex justify-end items-center gap-4 mb-5'>
              {/* <Button
                size="large"
                className='bg-white text-black border-custom-green rounded-lg h-9 font-semibold cursor-pointer'
                onClick={() => handleDraft()}
              >
                Save as draft
              </Button> */}
              <Button
                htmlType="submit"
                loading={addLeadLoading}
                className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
              >
                Submit Lead
              </Button>
            </div>
          </form>
        </div>
      </main>
    </div>
  )
}

export default AddLead