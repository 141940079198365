// Dashboard.js
import React, { useState, useEffect } from "react";
import '../../../common/components/Calender.css';
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import { Table } from "lucide-react";
import ReusableTable from "../../../common/tables/Table";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import searchIcon from '../../../data/Group 536.png';
import { useDispatch, useSelector } from "react-redux";
import { useRecoilState } from "recoil";
import { userIdAtom } from "../../../atom/authuserData/userdata";
import moment from 'moment/moment';
import { Spin } from 'antd';
import { getCommisionAction } from "../../../redux/actions/commisionAction";
import APIUrl from "../../../atom/APIUrl";

const CommisionPortalTable = () => {
  const [selectedTab, setSelectedTab] = useState('Information');
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userId, setuserId] = useRecoilState(userIdAtom);
  const [commissionTableData, setCommissionTableData] = useState([]);
  const { role } = useSelector((state) => state.auth);

  const { loading: commisionLoading, commissions } = useSelector(state => state.getCommissions);

  useEffect(()=>{
    console.log("Commission::",userId)
  },[commissionTableData])

  useEffect(() => {
    if (userId) {
      dispatch(getCommisionAction(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    let filteredData = []
    filteredData = commissions;

    const commissionData = []
    if (filteredData?.length > 0) {
      filteredData?.forEach((commission, index) => {
        commissionData.push({
          Key: index,
          // id: commission?._id,
          user_id: commission?.userId,
          compName: commission?.companyName,
          custName: `${commission?.customerName}`,
          date: commission?.date,
          lTag: commission?.leadTag,
          fAmount: commission?.fundedAmount,
        })
      })
    }
    const sortedcommissionData = commissionData?.sort((a, b) => moment(b.date) - moment(a.date))
    setCommissionTableData(sortedcommissionData);
  }, [commissions]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Company name',
        accessor: 'compName',
      },
      {
        Header: 'Customer name',
        accessor: 'custName',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span>
      },
      {
        Header: 'Lead tag',
        accessor: 'lTag',
      },
      {
        Header: 'Funded amount',
        accessor: 'fAmount',
        Cell: ({ value }) => (
          <span>{value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value) : "-"}
          </span>
        ),
      },
    ],
    []
  );

  const data = React.useMemo(
    () => [
      {
        compName: 'Apple',
        custName: 'Thomas Carefour',
        date: '15/10/2023',
        lTag: 'BV9000',
        fAmount: '$8.000'
      },
      {
        compName: 'Apple',
        custName: 'Thomas Carefour',
        date: '15/10/2023',
        lTag: 'BV9000',
        fAmount: '$8.000'
      },
      {
        compName: 'Apple',
        custName: 'Thomas Carefour',
        date: '15/10/2023',
        lTag: 'BV9000',
        fAmount: '$8.000'
      },
      {
        compName: 'Apple',
        custName: 'Thomas Carefour',
        date: '15/10/2023',
        lTag: 'BV9000',
        fAmount: '$8.000'
      },
      {
        compName: 'Apple',
        custName: 'Thomas Carefour',
        date: '15/10/2023',
        lTag: 'BV9000',
        fAmount: '$8.000'
      },
      {
        compName: 'Apple',
        custName: 'Thomas Carefour',
        date: '15/10/2023',
        lTag: 'BV9000',
        fAmount: '$8.000'
      },
      {
        compName: 'Apple',
        custName: 'Thomas Carefour',
        date: '15/10/2023',
        lTag: 'BV9000',
        fAmount: '$8.000'
      },
      {
        compName: 'Apple',
        custName: 'Thomas Carefour',
        date: '15/10/2023',
        lTag: 'BV9000',
        fAmount: '$8.000'
      },
      {
        compName: 'Apple',
        custName: 'Thomas Carefour',
        date: '15/10/2023',
        lTag: 'BV9000',
        fAmount: '$8.000'
      },
      {
        compName: 'Apple',
        custName: 'Thomas Carefour',
        date: '15/10/2023',
        lTag: 'BV9000',
        fAmount: '$8.000'
      }

    ],
    []
  );

  const handleTabClick = (tab) => {
    // console.log("Tab ---------------  ", tab)
    setSelectedTab(tab);
  };

  const LabeledTextarea = ({ label, placeholder, className }) => (
    <div className="flex flex-col w-full">
      <label className=" font-medium text-good-morning-text bg-white">{label}</label>
      <textarea
        placeholder={placeholder}
        className={`border border-gray-300 bg-white w-full  mt-3 h-56 resize-none rounded-md ${className}`}
      />
    </div>
  );

  return (
    <div className="flex h-screen bg-black">
      <DashboardTab />
      <main className="flex-1 bg-background-color p-6 h-full flex flex-col">
        <GoodMorningBar userName="Manish" />
        {commisionLoading?
         <div className='w-full pt-16 flex justify-center items-center' >
         <Spin />
       </div>  
       :
       <div>

        <div className="flex justify-between w-full mr-9 my-6">
          <div className="flex items-center">
            <div className="mt-3 ml-8 text-lender-green-text font-bold text-xl">
              My commisions
            </div>
          </div>
          <div onClick={() => navigate('/createCommision')} className="mt-3 mr-8 h-9 px-14 py-1 rounded-lg text-white bg-custom-green font-semibold cursor-pointer">
            Submit new form
          </div>
        </div>
        <div className="flex-grow overflow-y-auto mx-4 mb-5">
          <div className='mt-4'>
            <div className='bg-white rounded-tl-xl rounded-tr-xl p-3'>
              <div className="flex items-center bg-background-color rounded-3xl p-2 h-10 w-full mr-3">
                <img
                  src={searchIcon}
                  alt="Search Icon"
                  className="w-8 h-8 mr-2"
                  />
                <input
                  type="text"
                  placeholder="Search information"
                  className="bg-background-color focus:outline-none pl-3 w-full"
                  onChange={(e) => setSearchText(e.target.value)}
                  />
              </div>
            </div>
            {role === "operations" ?
              <Spin spinning={commisionLoading}>
                <ReusableTable columns={columns} data={commissionTableData} />
              </Spin>
              : <ReusableTable columns={columns} data={commissionTableData} />}
          </div>
        </div>
              </div>
    }
      </main>
    </div>
  );
};

export default CommisionPortalTable;
