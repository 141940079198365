"use client"
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { Button, Col, Input, Modal, Row, Select } from 'antd'
import { Controller, set, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { DownOutlined } from '@ant-design/icons'
import { FaCircle } from "react-icons/fa";
import { useEffect } from 'react'
import { approveLenderInSubmissionBoardAction, declineLenderInSubmissionBoardAction } from '../../../redux/actions/submissionBoardAction'
import { userIdAtom } from '../../../atom/authuserData/userdata'
import { useRecoilState } from 'recoil'
import { useDispatch, useSelector } from 'react-redux'
import { getDealsByLenderAction } from '../../../redux/actions/lenderAction'

// const schema = yup
//   .object({
//     status: yup
//       .string()
//       .required("Status is required"),
//     frequency: yup
//       .string()
//       .when('status', {
//         is: 'approve',
//         then: yup.string().required("Frequency is required"),
//         otherwise: yup.string().nullable(), // not required for other statuses
//       }),
//     fundingAmount: yup
//       .string()
//       .when('status', {
//         is: 'approve',
//         then: yup.string().required("Funding Amount is required"),
//         otherwise: yup.string().nullable(),
//       }),
//     paybackAmount: yup
//       .string()
//       .when('status', {
//         is: 'approve',
//         then: yup.string().required("Payback Amount is required"),
//         otherwise: yup.string().nullable(),
//       }),
//     num_of_amount: yup
//       .string()
//       .when('status', {
//         is: 'approve',
//         then: yup.string().max(100).required("Number of Payment is required"),
//         otherwise: yup.string().nullable(),
//       }),
//     payment: yup
//       .string()
//       .when('status', {
//         is: 'approve',
//         then: yup.string().required("Payment is required"),
//         otherwise: yup.string().nullable(),
//       }),
//     tenure: yup
//       .string()
//       .when('status', {
//         is: 'approve',
//         then: yup.string().required("Tenure is required"),
//         otherwise: yup.string().nullable(),
//       }),
//     factorRate: yup
//       .string()
//       .when('status', {
//         is: 'approve',
//         then: yup.string().required("Factor Rate is required"),
//         otherwise: yup.string().nullable(),
//       }),
//   })
//   .required();

const defaultValues = {
  lenderName: "",
  status: "",
  fundingAmount: "",
  buyRate: "",
  upSellRate: "0.1",
  sellRate: "",
  paybackAmount: "",
  frequency: "Daily",
  term: "",
  payment: "",
  reason: ""
}

const AddLenderModal = ({ deal, slectedLender, showEditLenderModal, setShowEditLenderModal }) => {
  const [userId, setuserId] = useRecoilState(userIdAtom);
  const { loading: approveLenderLoading } = useSelector(state => state.approveLenderInSubmissionBoard)
  const { loading: declineLenderLoading } = useSelector(state => state.declineLenderInSubmissionBoard)
  
  


  const dispatch = useDispatch()

  const frequencyOptions = [
    { label: 'Daily', value: 'Daily' },
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Yearly', value: 'Yearly' },
  ];

  const options = [
    {
      label: <span className='text-green-500 flex justify-start items-center gap-2'>
        <FaCircle size="10px" />
        Approve
      </span>,
      value: 'approve',
    },
    // {
    //   label: <span className='text-orange-300 flex justify-start items-center gap-2'>
    //     <FaCircle size="10px" />
    //     Pending
    //   </span>,
    //   value: 'pending',
    // },
    {
      label: <span className='text-red-500 flex justify-start items-center gap-2'>
        <FaCircle size="10px" />
        Decline
      </span>,
      value: 'decline',
    }
  ];

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues,
    // resolver: yupResolver(schema)
  })

  const buyRate = watch("buyRate");
  const upSellRate = watch("upSellRate");
  const fundingAmount = watch("fundingAmount")
  const sellRate = watch("sellRate")
  const paybackAmount = watch("paybackAmount")
  const term = watch("term")

  useEffect(() => {
    if (paybackAmount && term && term > 0) {
      const calculatedPayment = Number(paybackAmount) / Number(term);
      setValue("payment", calculatedPayment.toFixed(2)); // Set calculated payment with 2 decimal points
    }
  }, [paybackAmount, term, setValue]);
 
  useEffect(() => {
    const calculatedSellRate = (Number(buyRate) || 0) + (Number(upSellRate) || 0);
    setValue("sellRate", calculatedSellRate.toFixed(2)); // Set the calculated sellRate
  }, [buyRate, upSellRate, setValue]);

  useEffect(()=>{
    const calculatedPaybackAmount =  (Number(sellRate) * 100) + Number(fundingAmount);
    setValue("paybackAmount",calculatedPaybackAmount)
  },[sellRate,fundingAmount])

  useEffect(() => {
    reset(defaultValues)
    if (slectedLender) {
      setValue("lenderName", slectedLender?.lender_name)
      setValue("status", slectedLender?.status === "Approved" ? "approve" : slectedLender?.status === "Declined" ? "decline" : "Select")
      setValue("buyRate", slectedLender?.buyRate || ""); // Set initial values
      setValue("upSellRate", slectedLender?.upSellRate || "0.1");
    }
  }, [slectedLender ,reset ,setValue])

  const onClose = () => {
    setShowEditLenderModal(false)
  }

  const watchFields = watch("status")

  console.log(watchFields, "watchFields")

  const onSubmit = async data => {
    if (watchFields === "approve") {
      let formattedData = {
        funding_amount: data?.fundingAmount,
        buy_rate: data?.buyRate,
        upsell_rate: data?.upSellRate,
        sell_rate: data?.sellRate,
        payback_amount: data?.paybackAmount,
        frequency: data?.frequency,
        num_of_amount: data?.term,
        payment: data?.payment,
      }
      const result = await dispatch(approveLenderInSubmissionBoardAction(userId, slectedLender?._id, formattedData))
      if (result?.status === 1) {
        toast.success(result?.message)
        dispatch(getDealsByLenderAction(userId, deal?._id));
        onClose()
        return
      } else {
        toast.error(result?.message)
        return
      }
    } else if (watchFields === "decline") {
      console.log("Decline reason::",data.reason)
      const result = await dispatch(declineLenderInSubmissionBoardAction(userId, slectedLender?._id, { reason: data?.reason }))
      console.log("Result Decline::",result)
      if (result?.status === 1) {
        toast.success(result?.message)
        dispatch(getDealsByLenderAction(userId, deal?._id));
        onClose()
        return
      } else {
        toast.error(result?.message)
        return
      }
    }
  }

  const selectedFrequency = watch("frequency")
  const paymentLabel =
  selectedFrequency === "Daily"
    ? "Daily Payment"
    : selectedFrequency === "Weekly"
    ? "Weekly Payment"
    : selectedFrequency === "Monthly"
    ? "Monthly Payment"
    : selectedFrequency === "Yearly"
    ? "Yearly Payment"
    : "Payment";


    const formatAmount = (amount) => {
      return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 0,
      }).format(amount);
    };
  return (
    <Modal
      open={showEditLenderModal}
      onCancel={onClose}
      width={700}
      footer={null}
      centered
      className="flex justify-center items-center"
      title={<h4>Add Lender</h4>}
    >
      <form className="pt-4 flex flex-col justify-between h-full w-full" onSubmit={handleSubmit(onSubmit)}>
        <Row className='border-b border-[#989FAD] py-2 mb-3'>
          <div className='text-base font-normal'>
            Lender Details
          </div>
        </Row>
        <Row gutter={16}>
          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-lenderName">
              Lender Name
            </label>
            <Controller
              name="lenderName"
              control={control}
              // rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-lenderName"
                  value={field.value}
                  placeholder='Enter lender name'
                  status={errors.lenderName ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.lenderName ? (
              <small className="text-red-500 capitalize">{errors.lenderName.message}</small>
            ) : null}
          </Col>

          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-status">
              Status
            </label>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select
                  size='large'
                  style={{ width: "100%" }}
                  options={options}
                  placeholder="Select status"
                  suffixIcon={<DownOutlined />}
                  {...field}
                  onChange={e => field.onChange(e)}
                />
              )}
            />
            {errors.status ? (
              <small className="text-red-500 capitalize">{errors.status.message}</small>
            ) : null}
          </Col>
        </Row>
        {watchFields === "approve" && (
          <>
            <Row gutter={16}>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-fundingAmount">
                  Funding Amount
                </label>
                <Controller
                  name="fundingAmount"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-fundingAmount"
                      placeholder='Enter funding amount'
                      value={field.value ? formatAmount(field.value) : ''} // Format value for display only
                      onChange={(e) => {
                        const rawValue = e.target.value.replace(/\D/g, ''); // Only allow digits
                        field.onChange(rawValue); // Update field with raw value
                      }}
                      status={errors.fundingAmount ? "error" : undefined}
                      // {...field}
                    />
                  )}
                />
                {errors.fundingAmount ? (
                  <small className="text-red-500 capitalize">{errors.fundingAmount.message}</small>
                ) : null}
              </Col>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-buyRate">
                  Buy Rate
                </label>
                <Controller
                  name="buyRate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-buyRate"
                      placeholder='Enter buy rate'
                      status={errors.buyRate ? "error" : undefined}
                      {...field}
                    />
                  )}
                />
                {errors.buyRate ? (
                  <small className="text-red-500 capitalize">{errors.buyRate.message}</small>
                ) : null}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-upSellRate">
                  Upsell Rate
                </label>
                <Controller
                  name="upSellRate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-upSellRate"
                      placeholder='Enter upsell rate'
                      status={errors.upSellRate ? "error" : undefined}
                      {...field}
                    />
                  )}
                />
                {errors.upSellRate ? (
                  <small className="text-red-500 capitalize">{errors.upSellRate.message}</small>
                ) : null}
              </Col>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-sellRate">
                  Sell Rate
                </label>
                <Controller
                  name="sellRate"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-sellRate"
                      placeholder='Enter number of sell rate'
                      readOnly
                      status={errors.sellRate ? "error" : undefined}
                      style={{
                        backgroundColor: '#f5f5f5', // Set your desired disabled color
                        cursor: 'not-allowed', // Optional: change cursor to indicate it's disabled
                      }}
                      {...field}
                    />
                  )}
                />
                {errors.sellRate ? (
                  <small className="text-red-500 capitalize">{errors.sellRate.message}</small>
                ) : null}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-paybackAmount">
                  Payback Amount
                </label>
                <Controller
                  name="paybackAmount"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-paybackAmount"
                      placeholder='Enter payback amount'
                      value={field.value ? formatAmount(field.value) : ''} // Format value for display only
                      onChange={(e) => {
                        const rawValue = e.target.value.replace(/\D/g, ''); // Only allow digits
                        field.onChange(rawValue); // Update field with raw value
                      }}
                      status={errors.paybackAmount ? "error" : undefined}
                      // {...field}
                    />
                  )}
                />
                {errors.paybackAmount ? (
                  <small className="text-red-500 capitalize">{errors.paybackAmount.message}</small>
                ) : null}
              </Col>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium block" htmlFor="input-frequency">
                  Frequency
                </label>
                <Controller
                  name="frequency"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      size="large"
                      options={frequencyOptions}
                      placeholder="Select frequency"
                      status={errors.frequency ? "error" : undefined}
                      className="w-full "
                      {...field}
                    />
                  )}
                />
                {errors.frequency ? (
                  <small className="text-red-500 capitalize">{errors.frequency.message}</small>
                ) : null}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-term">
                  Term
                </label>
                <Controller
                  name="term"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-term"
                      placeholder='Enter term'
                      status={errors.term ? "error" : undefined}
                      {...field}
                    />
                  )}
                />
                {errors.term ? (
                  <small className="text-red-500 capitalize">{errors.term.message}</small>
                ) : null}
              </Col>
              <Col className="mb-4" xs={24} md={12} lg={12}>
                <label className="mb-1 font-medium" htmlFor="input-payment">
                  {paymentLabel}
                </label>
                <Controller
                  name="payment"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      size="large"
                      id="input-payment"
                      placeholder={`Enter ${paymentLabel.toLowerCase()}`}
                      value={field.value ? formatAmount(field.value) : ''} // Format value for display only
                      onChange={(e) => {
                        const rawValue = e.target.value.replace(/\D/g, ''); // Only allow digits
                        field.onChange(rawValue); // Update field with raw value
                      }}
                      status={errors.payment ? "error" : undefined}
                      // {...field}
                    />
                  )}
                />
                {errors.payment ? (
                  <small className="text-red-500 capitalize">{errors.payment.message}</small>
                ) : null}
              </Col>
            </Row>
          </>
        )}
        {watchFields === "decline" && (
          <Row gutter={16}>
            <Col className="mb-4" xs={24} md={24} lg={24}>
              <label className="mb-1 font-medium" htmlFor="input-reason">
                Reason for Decline
              </label>
              <Controller
                name="reason"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input.TextArea
                    rows={4}
                    id="input-reason"
                    placeholder='Enter reason for decline'
                    status={errors.reason ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.reason ? (
                <small className="text-red-500 capitalize">{errors.reason.message}</small>
              ) : null}
            </Col>
          </Row>
        )}

        <div className='flex justify-between items-center gap-4 my-5'>
          <Button
            size="large"
            className='bg-white text-black border-custom-green rounded-lg h-9 font-semibold cursor-pointer'
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            loading={approveLenderLoading || declineLenderLoading}
            className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
          >
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default AddLenderModal