export const ADD_NEW_LENDER_REQUEST = 'ADD_NEW_LENDER_REQUEST';
export const ADD_NEW_LENDER_SUCCESS = 'ADD_NEW_LENDER_SUCCESS';
export const ADD_NEW_LENDER_FAIL = 'ADD_NEW_LENDER_FAIL';

export const GET_ALL_LENDERS_REQUEST = 'GET_ALL_LENDERS_REQUEST';
export const GET_ALL_LENDERS_SUCCESS = 'GET_ALL_LENDERS_SUCCESS';
export const GET_ALL_LENDERS_FAIL = 'GET_ALL_LENDERS_FAIL';

export const ADD_NEW_LENDER_TO_DEAL_REQUEST = 'ADD_NEW_LENDER_TO_DEAL_REQUEST';
export const ADD_NEW_LENDER_TO_DEAL_SUCCESS = 'ADD_NEW_LENDER_TO_DEAL_SUCCESS';
export const ADD_NEW_LENDER_TO_DEAL_FAIL = 'ADD_NEW_LENDER_TO_DEAL_FAIL';

export const GET_DEALS_BY_LENDER_REQUEST = 'GET_DEALS_BY_LENDER_REQUEST';
export const GET_DEALS_BY_LENDER_SUCCESS = 'GET_DEALS_BY_LENDER_SUCCESS';
export const GET_DEALS_BY_LENDER_FAIL = 'GET_DEALS_BY_LENDER_FAIL';

export const GET_DELETE_LENDERS_FAIL = "GET_DELETE_LENDERS_FAIL";
export const GET_DELETE_LENDERS_REQUEST = "GET_DELETE_LENDERS_REQUEST";
export const GET_DELETE_LENDERS_SUCCESS = "GET_DELETE_LENDERS_SUCCESS";

