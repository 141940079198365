import { Button, Empty, Spin } from 'antd';
import React from "react";
import { MdCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getRemovedLeadsAction, restoreLeadAction } from '../../redux/actions/complianceAction';
import { userIdAtom } from '../../atom/authuserData/userdata';
import { useRecoilState } from 'recoil';
import { toast } from 'react-toastify';

const LeadGridSquare = ({ leads, loading }) => {
  const { role } = useSelector((state) => state.auth);
  const [userId, setuserId] = useRecoilState(userIdAtom);

  const dispatch = useDispatch();

  const { loading: restoreLeadsLoading } = useSelector((state) => state.restoreLead);

  const handleRestore = async (leadId) => {
    const result = await dispatch(restoreLeadAction(userId, leadId));
    if (result?.status === 1) {
      toast.success("Lead restored successfully")
      dispatch(getRemovedLeadsAction(userId));
    } else {
      toast.error(result?.message)
    }
  }

  return (
    <Spin spinning={loading}>
      <div className="flex flex-wrap gap-4 p-4 h-96 overflow-y-scroll removedLeads-container">
        {leads?.length === 0 ?
          <div className='w-full'>
            <Empty description="No removed leads" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div> :
          leads?.map((lead, index) => (
            <div key={index} className="flex justify-start items-start gap-2 bg-declined-bgcolor rounded-lg shadow p-2 h-44 min-w-56 sm:min-w-full lg:min-w-56">
              <MdCancel color="red" size={26} className="top-3 left-2 rounded-full" />
              <div>
                <div className="text-lg font-semibold text-red-600">{lead.comName}</div>
                <div className="text-sm  font-semibold text-red-600">{lead.firstname}</div>
                <div className="text-sm  font-semibold text-red-600">{lead.lastname}</div>
                <div className="text-sm  font-semibold text-red-600">{lead.email}</div>
                <div className="text-sm  font-semibold text-red-600">{lead.phone}</div>
                <div className="text-sm  font-semibold text-red-600">{'$' + lead.contractAmount}</div>
                <div className="text-sm  font-semibold text-red-600">{'$' + lead.paymentAmount}</div>
              </div>
              {role === "admin" ?
                <div className='flex justify-center items-center h-full'>
                  <Button
                    disabled={restoreLeadsLoading}
                    className='border border-red-600 text-red-600 bg-transparent hover:scale-105'
                    onClick={() => handleRestore(lead.id)}
                  >
                    Restore
                  </Button>
                </div> : null}
            </div>
          ))}
      </div>
    </Spin>
  );
};

export default LeadGridSquare;
