import { axiosGet, axiosPost } from '../axios';
import {
  APPROVE_LENDER_IN_SB_FAIL,
  APPROVE_LENDER_IN_SB_REQUEST,
  APPROVE_LENDER_IN_SB_SUCCESS,
  DECLINE_LENDER_IN_SB_FAIL,
  DECLINE_LENDER_IN_SB_REQUEST,
  DECLINE_LENDER_IN_SB_SUCCESS,
  GET_ALL_SUBMISSIONS_FAIL,
  GET_ALL_SUBMISSIONS_REQUEST,
  GET_ALL_SUBMISSIONS_SUCCESS,
  SET_SHOWN_OLD_DEALS_BUTTON,
  GET_SUBMISSIONS_BOARD_DEAL_FAIL,
  GET_SUBMISSIONS_BOARD_DEAL_REQUEST,
  GET_SUBMISSIONS_BOARD_DEAL_SUCCESS,
} from '../types/submissionBoardTypes';

export const getAllSubmissionsAction = (userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SUBMISSIONS_REQUEST });

    const { data } = await axiosGet(`deals/getSubmissionBoardDeals?user_id=${userId}`);
    dispatch({ type: GET_ALL_SUBMISSIONS_SUCCESS, payload: data?.deals });
    return data?.deals;
  } catch (error) {
    dispatch({
      type: GET_ALL_SUBMISSIONS_FAIL,
      payload: error.response?.data?.message || error.message,
    });
  }
}

export const setShownOldDealsButton = (showOldDeals) => (dispatch) => {
  dispatch({ type: SET_SHOWN_OLD_DEALS_BUTTON, payload: showOldDeals });
}

export const approveLenderInSubmissionBoardAction = (userId, lenderId, formData) => async (dispatch) => {
  try {
    dispatch({ type: APPROVE_LENDER_IN_SB_REQUEST });

    const { data } = await axiosPost(`lenders/approveDealLender?user_id=${userId}&lender_id=${lenderId}`, formData);
    dispatch({ type: APPROVE_LENDER_IN_SB_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: APPROVE_LENDER_IN_SB_FAIL,
      payload: error.response?.data?.message,
    });
    return error.response?.data;
  }
}

export const declineLenderInSubmissionBoardAction = (userId, lenderId,reason) => async (dispatch) => {
  try {
    dispatch({ type: DECLINE_LENDER_IN_SB_REQUEST });

    // const { data } = await axiosGet(`lenders/declineDealLender?user_id=${userId}&lender_id=${lenderId}`);
    const { data } = await axiosGet(`lenders/declineDealLender?user_id=${userId}&lender_id=${lenderId}&reason=${encodeURIComponent(reason)}`);
    dispatch({ type: DECLINE_LENDER_IN_SB_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: DECLINE_LENDER_IN_SB_FAIL,
      payload: error.response?.data?.message,
    });
    return error.response?.data;
  }
}

// submission board deal
export const getSubmissionsBoardDealAction = (userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_SUBMISSIONS_BOARD_DEAL_REQUEST });
    const { data } = await axiosGet(`deals/getSubmissionBoardDeals?user_id=${userId}`);
    dispatch({ type: GET_SUBMISSIONS_BOARD_DEAL_SUCCESS, payload: data?.deals });
    return data?.deals;
  } catch (error) {
    dispatch({
      type: GET_SUBMISSIONS_BOARD_DEAL_FAIL,
      payload: error.response?.data?.message || error.message,
    });
  }
}
export const getApprovedSubmissionsBoardDealAction = (userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_SUBMISSIONS_BOARD_DEAL_REQUEST });
    const { data } = await axiosGet(`deals/getDealsForCommision?user_id=${userId}`);
    dispatch({ type: GET_SUBMISSIONS_BOARD_DEAL_SUCCESS, payload: data?.deals });
    return data?.deals;
  } catch (error) {
    dispatch({
      type: GET_SUBMISSIONS_BOARD_DEAL_FAIL,
      payload: error.response?.data?.message || error.message,
    });
  }
}