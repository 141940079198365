import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { Button, Col, DatePicker, Input, Modal, Row } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useRecoilState } from 'recoil'
import { userIdAtom } from '../../../atom/authuserData/userdata'
import moment from 'moment'
import { setFundingTargetAction } from '../../../redux/actions/dashboardAction'
import { useNavigate } from 'react-router'

const schema = yup
  .object({
    fundingAmount: yup
      .number()
      .required("Funding amount is required"),
    month: yup
      .string()
      .required("month is required"),
    year: yup
        .string()
        .required("year is required"),
  })
  .required();

const defaultValues = {
  fundingAmount: "",
  month: "",
  year:"",
}

const CreateFundungAmountTask = ({ showCreateFundingModal, setShowCreateFundingModal }) => {
  const [userId, setuserId] = useRecoilState(userIdAtom);
  const navigate= useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    reset,
    clearErrors, 
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onClose = () => {
    setShowCreateFundingModal(false)
  }

  const validateFundingAmount = (value) => {
    if (isNaN(value)) {
        return 'Funding amount should be a number';
    }
    return true; 
};

const formatAmount = (amount) => {
  return new Intl.NumberFormat('en-US', 
      { style: 'currency', currency: 'USD', 
          minimumFractionDigits: 0, 
          maximumFractionDigits: 2
       }).format(amount)
};

  const onSubmit = async data => {
    const formData = {
        fundingAmount: data.fundingAmount,
        month:moment(data.month).format("MMMM"),
        year:moment(data.year).format("YYYY"),
    }
    console.log("---------------fromatted data--------------------", formData)
    const result = await dispatch(setFundingTargetAction(userId, formData))
    if (result?.status === 1) {
      toast.success(result?.message);
      navigate("/set-funding target")
    } else {
      toast.error(result?.message)
    }
    onClose()
  }

  return (
    <Modal
      open={showCreateFundingModal}
      onCancel={onClose}
      width={600}
      footer={null}
      centered
      className="flex justify-center items-center"
      title={<h4 className='font-medium'>Set Funding Target</h4>}
    >
      <form className="pt-4 flex flex-col justify-between h-full w-full" onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={16}>
            <Col className="mb-4" xs={24}>
                <label className="mb-1 font-medium" htmlFor="input-fundingAmount">
                    Funding Amount
                </label>
                <Controller
                    name="fundingAmount"
                    control={control}
                    rules={{ validate: validateFundingAmount }}
                    render={({ field }) => (
                        <Input
                            size="large"
                            id="input-fundingAmount"
                            type='string'
                            status={errors.fundingAmount ? "error" : undefined}
                            value={field.value ? formatAmount(field.value) : ''} // Format value for display only
                            onChange={(e) => {
                              const rawValue = e.target.value.replace(/\D/g, ''); // Only allow digits
                              field.onChange(rawValue); // Update field with raw value
                            }}
                        />
                    )}
                />
                {errors.fundingAmount && (
                    <small className="text-red-500 capitalize">funding amount should be a number</small>
                )}
            </Col>

            <Col className="mb-4" xs={12}>
                <label className="mb-1 font-medium" htmlFor="input-month">
                    Select Month
                </label>
                <Controller
                    name="month"
                    control={control}
                    render={({ field }) => (
                    <DatePicker
                        size="large"
                        id="input-month"
                        format={"MMMM"}
                        picker="month"
                        status={errors.month ? "error" : undefined}
                        className="w-full"
                        {...field}
                    />
                    )}
                />
                {errors.month ? (
                    <small className="text-red-500 capitalize">{errors.month.message}</small>
                ) : null}
            </Col>

            <Col className="mb-4" xs={12}>
                <label className="mb-1 font-medium" htmlFor="input-year">
                    Select Year
                </label>
                <Controller
                    name="year"
                    control={control}
                    render={({ field }) => (
                    <DatePicker
                        size="large"
                        id="input-year"
                        format={"YYYY"}
                        picker="year"
                        status={errors.year ? "error" : undefined}
                        className="w-full"
                        {...field}
                    />
                    )}
                />
                {errors.year ? (
                    <small className="text-red-500 capitalize">{errors.year.message}</small>
                ) : null}
            </Col>
        </Row>
        <div className='flex justify-between items-center gap-4 my-5'>
          <Button
            size="large"
            className='bg-white text-black border-custom-green rounded-lg h-9 font-semibold cursor-pointer'
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
          >
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default CreateFundungAmountTask