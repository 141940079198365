import { axiosPost } from '../axios';
import { GET_SUBMISSION_DATA_FAIL, GET_SUBMISSION_DATA_REQUEST, GET_SUBMISSION_DATA_SUCCESS } from '../types/submissionChartTypes';

export const getSubmissionDataAction = (userId, fromDate, toDate) => async (dispatch) => {
  try {
    dispatch({ type: GET_SUBMISSION_DATA_REQUEST });

    const { data } = await axiosPost(`deals/getAgentCount?user_id=${userId}`, {
      startDate: fromDate,
      endDate: toDate,
    });

    dispatch({ type: GET_SUBMISSION_DATA_SUCCESS, payload: data?.data.funded , totalSubmission:data?.data , approveCounts:data?.data.approvedCounts });
  } catch (error) {
    dispatch({
      type: GET_SUBMISSION_DATA_FAIL,
      payload: error.response?.data?.message,
    });
  }
};
