import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useRecoilState } from 'recoil'
import { toast } from 'react-toastify'
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { userIdAtom } from '../../../atom/authuserData/userdata'
import ProfButton from '../../../data/profileupdate.png';
import { Button, Col, Input, Row, Switch } from 'antd'
import "../../../App.css"
import { addNewLenderAction } from '../../../redux/actions/lenderAction'

const schema = yup
  .object({
    lender_name: yup
      .string()
      .max(100)
      .required("Company name is required"),
    // .matches(/^[a-zA-Z\s]+$/, "Last Name should only contain alphabets"),
    owner_firstName: yup
      .string()
      .max(100)
      .required("Company contact name is required"),
    owner_mail: yup.string().email().matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, "Invalid Email").required("Email is required"),
    max_funding_amount: yup.string().required("Max funding amount is required"),
    monthly_minimums: yup.string().required("Monthly minimums is required"),
    tier: yup.string().required("Tier is required"),
    nsf: yup.string().required("nsf is required"),
    avg_daily_balance: yup.string().required("Average daily balance is required"),
    position_toWilling: yup.string().required("Position willing to fund is required"),
  })
  .required()

const defaultValues = {
  lender_name: "",
  owner_firstName: "",
  owner_mail: "",
  max_funding_amount: "",
  monthly_minimums: "",
  tier: "",
  nsf: "",
  avg_daily_balance: "",
  position_toWilling: "",
  isDaily: false,
  isWeekly: false,
  isMonthly: false,
}

const AddNewLenderForm = () => {
  const [userId, setuserId] = useRecoilState(userIdAtom);

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loading: addNewLenderLoading } = useSelector(state => state.addNewLender)

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = async data => {
    const ownerFirstName = data?.owner_firstName.split(" ")?.[0]
    const ownerLastName = data?.owner_firstName.split(" ")?.[1] ?? ""
    const formData = { ...data, owner_firstName: ownerFirstName, owner_lastName: ownerLastName }
    const result = await dispatch(addNewLenderAction(userId, formData))
    if (result?.status === 1) {
      toast.success(result.message)
      navigate('/lender-directory')
    } else {
      toast.error(result?.message)
    }
  }

  return (
    <form className="pt-4 w-full flex flex-col justify-between h-full" onSubmit={handleSubmit(onSubmit)}>
      <div className='w-full'>
        <div className="flex flex-col items-start gap-5 mb-6 w-full md:flex-row md:items-center">
          <div className="relative inline-block min-w-28 min-h-28">
            <img
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
              alt="company logo"
              className="w-28 h-28 rounded-full border-2 border-custom-green"
            />
            <div className="absolute bottom-0 right-0  p-1 ">
              <img
                src={ProfButton}
                alt="update"
                className="object-cover h-8  mt-1"
              />
            </div>
          </div>
          <div className='w-full flex flex-col justify-start items-center gap-4 md:flex-row'>
            <Col className="mb-4" xs={24} md={10}>
              <label className="mb-1 font-medium" htmlFor="input-lenderName">
                Company Name
              </label>
              <Controller
                name="lender_name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-lenderName"
                    placeholder='Enter Company Name'
                    status={errors.lender_name ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.lender_name ? (
                <small className="text-red-500 capitalize">{errors.lender_name.message}</small>
              ) : null}
            </Col>

            <Col className="mb-4" xs={24} md={13}>
              <label className="mb-1 font-medium" htmlFor="input-ownerFirstName">
                Company Contact Name
              </label>
              <Controller
                name="owner_firstName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-ownerFirstName"
                    placeholder='Enter company contact name'
                    status={errors.owner_firstName ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.owner_firstName ? (
                <small className="text-red-500 capitalize">{errors.owner_firstName.message}</small>
              ) : null}
            </Col>
          </div>
        </div>
        <Row gutter={16}>
          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-ownerMail">
              Company Contact Email
            </label>
            <Controller
              name="owner_mail"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-ownerMail"
                  placeholder='Enter Email'
                  status={errors.owner_mail ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.owner_mail ? (
              <small className="text-red-500 capitalize">{errors.owner_mail.message}</small>
            ) : null}
          </Col>

          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-maxFundingAmount">
              Max Funding Amount
            </label>
            <Controller
              name="max_funding_amount"
              control={control}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-maxFundingAmount"
                  placeholder='Enter max funding amount'
                  status={errors.max_funding_amount ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.max_funding_amount ? (
              <small className="text-red-500 capitalize">{errors.max_funding_amount.message}</small>
            ) : null}
          </Col>
          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-monthlyMinimums">
              Monthly minimums
              {/* <span className="text-red-500">*</span> */}
            </label>
            <Controller
              name="monthly_minimums"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-monthlyMinimums"
                  value={field.value}
                  placeholder='Enter monthly minimums'
                  status={errors.monthly_minimums ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.monthly_minimums ? (
              <small className="text-red-500 capitalize">{errors.monthly_minimums.message}</small>
            ) : null}
          </Col>
          <Col className="mb-4 flex justify-between gap-4" xs={24} md={12} lg={12}>
            <div className='w-fit flex flex-col'>
              <label className="mb-2 font-medium" htmlFor="input-isDaily">
                Daily
              </label>
              <Controller
                name="isDaily"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Switch
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </div>
            <div className='w-fit flex flex-col'>
              <label className="mb-2 font-medium" htmlFor="input-isWeekly">
                Weekly
              </label>
              <Controller
                name="isWeekly"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Switch
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </div>
            <div className='w-fit flex flex-col'>
              <label className="mb-2 font-medium" htmlFor="input-isMonthly">
                Bi-weekly/monthly
              </label>
              <Controller
                name="isMonthly"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Switch
                    className='w-11'
                    onChange={(e) => field.onChange(e)}
                  />
                )}
              />
            </div>
            <div className='w-fit flex flex-col'>
              <label className="mb-1 font-medium" htmlFor="input-tier">
                Tier
              </label>
              <Controller
                name="tier"
                control={control}
                render={({ field }) => (
                  <Input
                    size="large"
                    id="input-tier"
                    placeholder='Enter tier'
                    status={errors.tier ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.tier ? (
                <small className="text-red-500 capitalize">{errors.tier.message}</small>
              ) : null}
            </div>
          </Col>

          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-nsf">
              NSF
            </label>
            <Controller
              name="nsf"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-nsf"
                  placeholder='Enter nsf'
                  status={errors.nsf ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.nsf ? (
              <small className="text-red-500 capitalize">{errors.nsf.message}</small>
            ) : null}
          </Col>

          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-avgDailyBalance">
              Average Daily Balance
            </label>
            <Controller
              name="avg_daily_balance"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-avgDailyBalance"
                  placeholder='Enter average daily balance'
                  status={errors.avg_daily_balance ? "error" : undefined}
                  {...field}
                // onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.avg_daily_balance ? (
              <small className="text-red-500 capitalize">{errors.avg_daily_balance.message}</small>
            ) : null}
          </Col>
          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-positionToWilling">
              Position Willing to Fund
            </label>
            <Controller
              control={control}
              name="position_toWilling"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-positionToWilling"
                  placeholder='Enter position Willing to fund'
                  status={errors.position_toWilling ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.position_toWilling ? (
              <small className="text-red-500 capitalize">{errors.position_toWilling.message}</small>
            ) : null}
          </Col>
{/* -------------------------------------------------------------------------------- */}
          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-maximumPosition">
              Maximum Position
            </label>
            <Controller
              name="max_position"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-maximumPosition"
                  placeholder='Enter maximum position'
                  status={errors.max_position ? "error" : undefined}
                  {...field}
                // onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.max_position ? (
              <small className="text-red-500 capitalize">{errors.max_position.message}</small>
            ) : null}
          </Col>
          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-minimumPosition">
              Minimum Position
            </label>
            <Controller
              control={control}
              name="min_position"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-minimumPosition"
                  placeholder='Enter minimum position'
                  status={errors.min_position ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.min_position ? (
              <small className="text-red-500 capitalize">{errors.min_position.message}</small>
            ) : null}
          </Col>

          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-creditScoreMinimum">
              Credit Score Minimum
            </label>
            <Controller
              name="credit_score_min"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-creditScoreMinimum"
                  placeholder='Enter credit score minimum'
                  status={errors.credit_score_min ? "error" : undefined}
                  {...field}
                // onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.credit_score_min ? (
              <small className="text-red-500 capitalize">{errors.credit_score_min.message}</small>
            ) : null}
          </Col>
          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-creditScoreMinimumValue">
              Credit Score Minimum Value
            </label>
            <Controller
              control={control}
              name="credit_score_min_value"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-creditScoreMinimumValue"
                  placeholder='Enter credit score minimum value'
                  status={errors.credit_score_min_value ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.credit_score_min_value ? (
              <small className="text-red-500 capitalize">{errors.credit_score_min_value.message}</small>
            ) : null}
          </Col>

          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-maxTerm">
              Max Term
            </label>
            <Controller
              name="max_terms"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-maxTerm"
                  placeholder='Enter max term'
                  status={errors.max_terms ? "error" : undefined}
                  {...field}
                // onChange={e => /^[A-Za-z\s]*$/.test(e.target.value) && field.onChange(e)}
                />
              )}
            />
            {errors.max_terms ? (
              <small className="text-red-500 capitalize">{errors.max_terms.message}</small>
            ) : null}
          </Col>
          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-tib">
              TIB
            </label>
            <Controller
              control={control}
              name="tib"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-tib"
                  placeholder='Enter TIB'
                  status={errors.tib ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.tib ? (
              <small className="text-red-500 capitalize">{errors.tib.message}</small>
            ) : null}
          </Col>

          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-restrictedIndustry">
              Restricted Industry
            </label>
            <Controller
              control={control}
              name="restricted_industry"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-restrictedIndustry"
                  placeholder='Enter restricted industry'
                  status={errors.restricted_industry ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.restricted_industry ? (
              <small className="text-red-500 capitalize">{errors.restricted_industry.message}</small>
            ) : null}
          </Col>
          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-restrictedState">
              Restricted State
            </label>
            <Controller
              control={control}
              name="restricted_state"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-restrictedState"
                  placeholder='Enter restricted state'
                  status={errors.restricted_state ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.restricted_state ? (
              <small className="text-red-500 capitalize">{errors.restricted_state.message}</small>
            ) : null}
          </Col>
          <Col className="mb-4" xs={24} md={12} lg={12}>
            <label className="mb-1 font-medium" htmlFor="input-preferredIndustry">
              Preferred Industry
            </label>
            <Controller
              control={control}
              name="prefered_industry"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-preferredIndustry"
                  placeholder='Enter preferred industry'
                  status={errors.prefered_industry ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.prefered_industry ? (
              <small className="text-red-500 capitalize">{errors.prefered_industry.message}</small>
            ) : null}
          </Col>
        </Row>
      </div>
      <div className='flex justify-end items-center gap-4 mb-5'>
        <Button
          size='large'
          htmlType="submit"
          loading={addNewLenderLoading}
          disabled={addNewLenderLoading}
          className='bg-custom-green text-white rounded-lg h-10 font-semibold cursor-pointer'
        >
          Add lender to directory
        </Button>
      </div>
    </form>
  )
}

export default AddNewLenderForm