import { Outlet , Navigate } from "react-router-dom";
import { userIdAtom } from "../atom/authuserData/userdata";
import { useRecoilValue } from "recoil";

const ProtectedRoutes  = () => {

    const userId = useRecoilValue(userIdAtom);
    return userId  ? <Outlet/> : <Navigate to="/"/>
}

export default ProtectedRoutes;