import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import { Button, Col, DatePicker, Input, Modal, Row } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import "./CreateNewTask.css"
import { useDispatch } from 'react-redux'
import { createNewTaskAction, getAllTasksAction } from '../../../redux/actions/dashboardAction'
import { useRecoilState } from 'recoil'
import { userIdAtom } from '../../../atom/authuserData/userdata'
import moment from 'moment'

const schema = yup
  .object({
    task: yup
      .string()
      .max(100)
      .required("Task description is required"),
    dueDate: yup
      .string()
      .required("Due date is required")
  })
  .required()

const defaultValues = {
  task: "",
  dueDate: ""
}

const CreateNewTask = ({ showCreateTaskModal, setShowCreateTaskModal ,refreshTasks }) => {
  const [userId, setuserId] = useRecoilState(userIdAtom);

  const dispatch = useDispatch()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onClose = () => {
    setShowCreateTaskModal(false)
  }

  const onSubmit = async data => {
    const formData = {
      task: data.task,
      dueDate: moment(data.dueDate).format("DD-MM-YYYY")
    }
    const result = await dispatch(createNewTaskAction(userId, formData))
    if (result?.status === 1) {
      toast.success(result?.message)
      dispatch(getAllTasksAction(userId, moment(formData.dueDate).format("DD-MM-YYYY")));
      refreshTasks();

    } else {
      toast.error(result?.message)
    }
    onClose()
  }

  return (
    <Modal
      open={showCreateTaskModal}
      onCancel={onClose}
      width={600}
      footer={null}
      centered
      className="flex justify-center items-center"
      title={<h4 className='font-medium'>Create Task</h4>}
    >
      <form className="pt-4 flex flex-col justify-between h-full w-full" onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={16}>
          <Col className="mb-4" xs={24}>
            <label className="mb-1 font-medium" htmlFor="input-task">
              Task
              {/* <span className="text-red-500">*</span> */}
            </label>
            <Controller
              name="task"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="large"
                  id="input-task"
                  status={errors.task ? "error" : undefined}
                  {...field}
                />
              )}
            />
            {errors.task ? (
              <small className="text-red-500 capitalize">{errors.task.message}</small>
            ) : null}
          </Col>

          <Col className="mb-4" xs={24}>
            <label className="mb-1 font-medium" htmlFor="input-dueDate">
              Due date
            </label>
            <Controller
              name="dueDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  size="large"
                  id="input-dueDate"
                  format={"DD-MMM-YYYY"}
                  status={errors.dueDate ? "error" : undefined}
                  className="w-full"
                  {...field}
                />
              )}
            />
            {errors.dueDate ? (
              <small className="text-red-500 capitalize">{errors.dueDate.message}</small>
            ) : null}
          </Col>
        </Row>
        <div className='flex justify-between items-center gap-4 my-5'>
          <Button
            size="large"
            className='bg-white text-black border-custom-green rounded-lg h-9 font-semibold cursor-pointer'
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            className='bg-custom-green text-white rounded-lg h-9 font-semibold cursor-pointer'
          >
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default CreateNewTask