import React, { useEffect, useState } from 'react';
import { Empty, Spin, Timeline } from 'antd';
import moment from 'moment/moment';
import { FaCircle } from "react-icons/fa";
import { HiOutlinePencil } from "react-icons/hi2";
import "../../../../App.css"
import { useDispatch, useSelector } from 'react-redux';
import { getActivittyLogsAction } from '../../../../redux/actions/leadsActions';
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../../atom/authuserData/userdata';

// Helper function to group logs by date
const groupByDate = async (logs) => {
  const groupedLogs = logs?.reduce((acc, log) => {
    const date = moment(log.createdAt).format('MM/DD/YYYY');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(log);
    return acc;
  }
    , {});
  return groupedLogs;
};

const ActivityLogs = ({ id, leadTag }) => {
  const [userId, setuserId] = useRecoilState(userIdAtom);
  const [items, setItems] = useState([]);

  const dispatch = useDispatch();
  const { loading: activityLogsLoading, activityLogs } = useSelector((state) => state.getActivittyLogs);

  useEffect(() => {
    dispatch(getActivittyLogsAction(userId, id));
  }, [id, leadTag]);

  useEffect(() => {
    const getGroupedLogs = async () => {
      let tempItems = []
      const tempActitvityLogs = activityLogs && [...activityLogs];
      const groupedLogs = await groupByDate(tempActitvityLogs?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt)))
      for (let x in groupedLogs) {
        tempItems.push(
          {
            color: "#00A762",
            dot: <div className='p-1 bg-background-color'>
              <div className='text-xl font-bold'>
                {moment(x).format("DD")}
              </div>
              <div className='text-sm font-medium'>
                {moment(x).format("MMM, YYYY")}
              </div>
            </div>,
            children: (
              <div>
                {groupedLogs[x].map((log) => (
                  <div key={log._id} className='flex justify-between items-center gap-4 bg-white h-20 p-3 pl-5 mb-2 rounded-md ml-8'>
                    <div className=''>
                      <div className='flex justify-start items-center gap-2'>
                        <span className='text-custom-green text-sm font-medium capitalize'>{`${log?.first_name} ${log?.last_name}`}</span>
                        <span className='w-[11px] h-[11px] flex justify-center items-center'><FaCircle size={8} className='text-custom-green' /></span>
                        <span className='text-custom-green text-sm'>{moment(log.createdAt).format('LT')}</span>
                      </div>
                      <div className='text-base font-semibold'>{log.activity_logs}</div>
                    </div>
                    <div className='w-10 h-10 flex justify-center items-center cursor-pointer'>
                      <HiOutlinePencil size={20} />
                    </div>
                  </div>
                ))}
              </div>
            ),
          })
      }
      setItems(tempItems)
    }

    getGroupedLogs()
  }, [activityLogs]);

  return (
    <div className='w-full pl-16 overflow-y-scroll h-[80%] mt-7'>
      {activityLogsLoading ?
        <div className='flex justify-center mt-5 w-full'>
          <Spin />
        </div> :
        items?.length > 0 ?
          <Timeline
            items={items}
          /> :
          <div className='w-full'>
            <Empty description="No Activity Recorded" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>

      }
    </div>
  );
};

export default ActivityLogs;
