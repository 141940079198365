import { Col, Input, message, Modal, Row, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../atom/authuserData/userdata';
import ReusableTable from '../../../common/tables/Table';
import { useEffect, useMemo, useState } from 'react';
import { addNewLenderToDealAction, getAllLendersAction, getDealsByLenderAction } from '../../../redux/actions/lenderAction';
import { IoIosAddCircleOutline } from 'react-icons/io';
import moment from 'moment';
import AddNewLenderConfirmModal from '../../tabScreens/DealsScreen/InduvidualDealTabs/AddNewLenderConfirmModal';
import { toast } from 'react-toastify';

const AddNewLenderToDeal = ({ deal, showAddNewLenderToDealModal, setShowAddNewLenderToDealModal }) => {
  const { role } = useSelector((state) => state.auth);
  const [userId, setuserId] = useRecoilState(userIdAtom);
  const [position, setPosition] = useState('');
  const [credit, setCredit] = useState('');
  const [amount, setAmount] = useState('');
  const [monthlyMin, setMonthlyMin] = useState('');
  const [showAddNewLenderConfirmModal, setShowAddNewLenderConfirmModal] = useState(false);
  const [selectedNewLender, setSelectedNewLender] = useState(null);
  const [lenderTableData, setLenderTableData] = useState([]);

  const dispatch = useDispatch();
  const { loading: allLenderLoading, lenders } = useSelector(state => state.getAllLenders);

  useEffect(() => {
    dispatch(getAllLendersAction(userId));
  }, []);

  useEffect(() => {
    let filteredData = lenders;

    if (credit) {
      filteredData = lenders.filter(lender =>
        lender?.avg_daily_balance >= credit
      );
    }

    if (amount) {
      filteredData = lenders.filter(lender =>
        lender?.max_funding_amount >= amount
      );
    }

    if (monthlyMin) {
      filteredData = lenders.filter(lender =>
        lender?.monthly_minimums >= monthlyMin
      );
    }
    if (position) {
      filteredData = lenders.filter(lender =>
        lender?.position_toWilling?.includes(position)
      );
    }

    if (filteredData?.length === 0) {
      filteredData = [];
    }
    const lenderData = []
    if (filteredData?.length > 0) {
      filteredData?.forEach((lender) => {
        lenderData.push({
          lender: {
            company: lender?.lender_name,
            ceo: `${lender?.owner_firstName} ${lender?.owner_lastName}`,
            ceoFirstName: lender?.owner_firstName,
            ceoLastName: lender?.owner_lastName,
            ceoMail: lender?.owner_mail
          },
          positions: lender?.position_toWilling,
          credit: lender?.avg_daily_balance,
          amount: lender?.max_funding_amount,
          monthlyMin: lender?.monthly_minimums,
          createdAt: lender?.createdAt,
          addButton: lender,
        });
      });
    }
    setLenderTableData(lenderData?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt)));
  }, [lenders, position, credit, amount, monthlyMin]);

  const onClose = () => {
    setShowAddNewLenderToDealModal(false)
  }

  const handleAddNewLenderModal =async (value) => {
    setSelectedNewLender(value);
    handleSubmit(value)
    // setShowAddNewLenderConfirmModal(true);
  }

  const filterLenderColumns = useMemo(
    () => [
      {
        Header: 'Lender',
        accessor: 'lender',
        Cell: ({ value }) => (
          <div>
            <div className="text-good-morning-text font-semibold capitalize">{value.company} / {value.ceoFirstName}</div>
            <div>{value.ceoMail}</div>
          </div>
        ),
      },
      {
        Header: 'Positions',
        accessor: 'positions',
      },
      {
        Header: 'Credit',
        accessor: 'credit',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value }) => {
          const formattedValue = value
            ? new Intl.NumberFormat('en-US').format(value)
            : "-";
          return <span>{value ? `$${formattedValue}` : "-"}</span>;
        },
      },
      {
        Header: 'Monthly min',
        accessor: 'monthlyMin',
        Cell: ({ value }) => {
          const formattedValue = value
            ? new Intl.NumberFormat('en-US').format(value)
            : "-";
          return <span>{value ? `$${formattedValue}` : "-"}</span>;
        },
      },
      {
        Header: 'Add lender to deal',
        accessor: 'addButton',
        Cell: ({ value }) => (
          <div className="flex ">
            <IoIosAddCircleOutline className="text-gray-600 cursor-pointer" size={30} onClick={() => handleAddNewLenderModal(value)} />
          </div>
        ),
      },
    ],
    []
  );

  const handleSubmit = async (selectedLender) => {
    const result = await dispatch(addNewLenderToDealAction(userId, deal?._id, selectedLender?._id))
    console.log("Adding Lender::",userId, deal?._id, selectedLender)
    console.log(result, 'result')
    if (result?.status === 1) {
      setShowAddNewLenderConfirmModal(false)
      toast.success('New lender added to deal successfully')
      dispatch(getDealsByLenderAction(userId, deal?._id));
    } else {
      // toast.error('Failed to add new lender to deal')
      toast.error(result.message)
    }
  }

  return (
    <Modal
      open={showAddNewLenderToDealModal}
      onCancel={onClose}
      width={1000}
      height={700}
      footer={null}
      centered
      className="flex justify-center items-center overflow-y-scroll"
      title={<h4>Add Lender</h4>}
    >
      <div>
        <div className="bg-white rounded-lg py-5 px-7">
          <div className="text-lg mb-6 font-semibold text-good-morning-text">
            Filter lenders
          </div>
          <div className="space-y-7">
            <Row gutter={24}>
              <Col xs={6}>
                <label className="text-good-morning-text font-semibold">Positions</label>
                <Input
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  placeholder="Enter Positions"
                />
              </Col>
              <Col xs={6}>
                <label className="text-good-morning-text font-semibold">Credit</label>
                <Input
                  value={credit}
                  onChange={(e) => /^[0-9\s]*$/.test(e.target.value) && setCredit(e.target.value)}
                  placeholder="Credit"
                />
              </Col>
              <Col xs={6}>
                <label className="text-good-morning-text font-semibold">Amount</label>
                <Input
                  value={amount}
                  onChange={(e) => /^[0-9\s]*$/.test(e.target.value) && setAmount(e.target.value)}
                  placeholder="Amount"
                />
              </Col>
              <Col xs={6}>
                <label className="text-good-morning-text font-semibold">Monthly Minimum</label>
                <Input
                  value={monthlyMin}
                  onChange={(e) => /^[0-9\s]*$/.test(e.target.value) && setMonthlyMin(e.target.value)}
                  placeholder="Enter monthly minimum"
                />
              </Col>
            </Row>
          </div>
        </div>
        <Spin spinning={allLenderLoading}>
          <ReusableTable columns={filterLenderColumns} data={lenderTableData} />
        </Spin>
      </div>
      {showAddNewLenderConfirmModal &&
        <AddNewLenderConfirmModal
          userId={userId}
          deal={deal}
          selectedNewLender={selectedNewLender}
          showAddNewLenderConfirmModal={showAddNewLenderConfirmModal}
          setShowAddNewLenderConfirmModal={setShowAddNewLenderConfirmModal}
        />
      }
    </Modal>
  )
}

export default AddNewLenderToDeal